@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
body {
  min-height: 100vh;
  height: 100%;
  font-family: "Plus Jakarta Sans", serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body.no-js .intro {
  opacity: 1;
  transform: none;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body .btn {
  font-weight: 600;
  padding: 9px 25px;
  border-radius: 50px;
  font-size: 14px;
  letter-spacing: 0.5px;
  transition: all 0.4s;
}
body .btn span {
  font-weight: 300;
}
body .btn.btn-default {
  background: #c31432;
  color: #fff;
}
body .btn.btn-default:hover {
  background: #950f26;
}
body .btn.btn-secondary {
  background: #fff;
  color: #000;
  border: 1px solid #c31432;
}
body .btn.btn-secondary:hover {
  background: #c31432;
  color: #fff;
}
body .btn.btn-white {
  background: #fff;
  color: #c31432;
}
body .btn.btn-white:hover {
  color: #fff;
  background: #660b1a;
}
body .btn.btn-big {
  font-size: 14px;
  padding: 12px 35px 10px 35px;
}
body .btn.btn-small {
  padding: 7px 15px 6px 15px;
  font-size: 10px;
}
body .btn.btn-fixed {
  position: fixed;
  z-index: 999;
  right: 20px;
  bottom: 20px;
}
body ul, body ol, body p, body h1, body h2, body h3, body h4, body h5, body h6 {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none;
}

.body-wrapper {
  overflow: hidden;
}

.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
.alert-wrapper .alert-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  transform: translateX(8px);
  padding: 50px 0;
}
.alert-wrapper .alert-service .service-inner {
  padding: 50px;
  background: #fff;
  width: 100%;
  box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
  text-align: center;
  border-top: 5px solid #fff;
  position: relative;
  transform: scale(0.8);
  transition: all 0.5s;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss {
  top: 10px;
  right: 10px;
  position: absolute;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  padding: 5px;
  outline: none;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss img {
  height: 15px;
}
.alert-wrapper .alert-service .service-inner.success {
  border-top: 5px solid #78b042;
}
.alert-wrapper .alert-service .service-inner.warning {
  border-top: 5px solid #d51a27;
}
.alert-wrapper .alert-service .service-inner.info {
  border-top: 5px solid #1a88d5;
}
.alert-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.alert-wrapper.active .alert-service .service-inner {
  transform: none;
}

.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 990px;
  max-width: 100%;
  padding: 25px;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 16px;
  right: 12px;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-button svg {
  fill: #000;
  height: 20px;
  min-width: 20px;
  max-width: 20px;
  transform: rotate(45deg);
  transition: all 0.3s;
}
.modal-service-wrapper .modal-service .service-inner .service-button:hover svg {
  fill: #c31432;
}
.modal-service-wrapper .modal-service .service-inner .service-content .heading {
  font-size: 25px;
  color: #000;
  font-weight: 500;
  padding-right: 50px;
}
.modal-service-wrapper .modal-service .service-inner .service-content .description {
  margin-top: 35px;
}
.modal-service-wrapper .modal-service .service-inner .service-content .description p, .modal-service-wrapper .modal-service .service-inner .service-content .description li {
  color: #000;
  font-size: 17px;
  line-height: 35px;
  font-weight: 400;
}
.modal-service-wrapper .modal-service .service-inner .service-content .description ol, .modal-service-wrapper .modal-service .service-inner .service-content .description ul {
  padding-left: 17px;
}
.modal-service-wrapper .modal-service .service-inner .service-content .description h1 {
  color: #c31432;
  font-size: 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.modal-service-wrapper .modal-service .service-inner .service-content .description h2 {
  color: #c31432;
  font-size: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.modal-service-wrapper .modal-service .service-inner .service-content .description h3 {
  color: #c31432;
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.modal-service-wrapper.full-width .modal-service {
  padding: 0;
}
.modal-service-wrapper.full-width .modal-service .service-inner {
  width: 100%;
  min-height: 100vh;
}

.fancybox-image {
  border-radius: 30px;
}

.pricing-clock-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  background: #222;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  align-items: center;
}
.pricing-clock-widget .pricing-clock-heading {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  margin-right: 25px;
}
.pricing-clock-widget .pricing-clock {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricing-clock-widget .pricing-clock .clock-service {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pricing-clock-widget .pricing-clock .clock-service .value {
  background: #c31432;
  color: #fff;
  min-width: 35px;
  max-width: 35px;
  height: 35px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricing-clock-widget .pricing-clock .clock-service .name {
  margin-top: 3px;
  font-size: 8px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
}
.pricing-clock-widget .pricing-clock .clock-service + .clock-service {
  margin-left: 15px;
}

.section-viewport-point {
  position: absolute;
  top: -25px;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.checkbox-wrapper {
  margin-top: 15px;
  margin-bottom: 0;
  cursor: pointer;
  width: 100%;
  display: block;
  position: relative;
}
.checkbox-wrapper .radio-box {
  display: flex;
}
.checkbox-wrapper .radio-box .box-square {
  content: "";
  min-width: 12px;
  max-width: 12px;
  height: 12px;
  display: block;
  border-radius: 2px;
  border: 1.5px dotted #999;
  margin-top: 0px;
  margin-right: 10px;
  transition: all 0.3s;
}
.checkbox-wrapper .radio-box .box-text {
  font-size: 11px;
  letter-spacing: -0.5px;
  line-height: 10px;
  text-align: left;
}
.checkbox-wrapper input {
  opacity: 0;
  left: 0;
  position: absolute;
}

.checkbox-wrapper > input:checked + .radio-box .box-square {
  background: #c31432;
  border-color: #c31432;
}

nav.section-header {
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.4s;
}
nav.section-header .header-flexbox {
  padding: 40px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: padding 0.8s;
}
nav.section-header .header-flexbox .flexbox-logo {
  transition: all 0.5s;
}
nav.section-header .header-flexbox .flexbox-logo img {
  height: 25px;
}
nav.section-header .header-flexbox .flexbox-logo .logo-text {
  font-weight: 500;
  font-size: 25px;
  font-weight: 600;
  color: #000;
  transition: all 0.4s;
}
nav.section-header .header-flexbox .flexbox-logo .logo-text span {
  color: #c31432;
  font-size: 40px;
  line-height: 10px;
}
nav.section-header .header-flexbox .flexbox-cta ul {
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-cta ul li .cta-contact {
  margin-top: 3px;
  display: flex;
  align-items: center;
  transition: all 0.5s;
}
nav.section-header .header-flexbox .flexbox-cta ul li .cta-contact span {
  font-size: 14px;
  color: #000;
  white-space: nowrap;
  transition: all 0.4s;
}
nav.section-header .header-flexbox .flexbox-cta ul li .cta-contact span b {
  font-weight: 700;
}
nav.section-header .header-flexbox .flexbox-cta ul li .cta-contact svg {
  margin: 0 8px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  fill: #c31432;
  transform: translateY(-2px);
}
nav.section-header .header-flexbox .flexbox-cta ul li .cta-contact:hover span b {
  color: #c31432;
}
nav.section-header .header-flexbox .flexbox-cta ul li + li {
  margin-left: 30px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper {
  transition: all 0.5s;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:before, nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a:not(.btn) {
  font-weight: 700;
  font-size: 14px;
  color: #000;
  transition: color 0.4s;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a:not(.btn) img {
  height: 20px;
  transform: translateY(-3px);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a:not(.btn):hover {
  color: #c31432;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li + li {
  margin-left: 35px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.btn-cta {
  display: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.social-media {
  margin-left: 20px;
}
nav.section-header .header-flexbox .nav-button {
  padding: 10px;
  border: none;
  background: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  display: none;
}
nav.section-header .header-flexbox .nav-button .button-bar {
  height: 2px;
  width: 28px;
  background: #000;
  border-radius: 10px;
  transition: all 0.4s;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(2) {
  margin-top: 6px;
  width: 32px;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(3) {
  margin-top: 6px;
  width: 36px;
}
nav.section-header .header-flexbox .nav-button:hover .button-bar, nav.section-header .header-flexbox .nav-button:focus .button-bar {
  width: 36px !important;
}
nav.section-header.scrolling-down {
  transform: translateY(-10px);
  background: none !important;
}
nav.section-header.scrolling-down .header-flexbox .flexbox-logo, nav.section-header.scrolling-down .header-flexbox .flexbox-nav-wrapper, nav.section-header.scrolling-down .header-flexbox .cta-contact {
  opacity: 0;
}
nav.section-header.scrolled {
  background: rgba(255, 255, 255, 0.75);
}
nav.section-header.scrolled .header-flexbox {
  padding: 25px 0;
}
nav.section-header.scrolled:hover {
  background: rgba(255, 255, 255, 0.95);
}
nav.section-header.white .header-flexbox .flexbox-logo .logo-text {
  color: #fff;
}
nav.section-header.white .header-flexbox .flexbox-cta ul li .cta-contact span {
  color: #fff;
}
nav.section-header.white .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
  color: #fff;
}
nav.section-header.white .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a:hover {
  color: #c31432;
}
nav.section-header.white .header-flexbox .nav-button .button-bar {
  background: #fff;
}

section.section-welcome {
  position: relative;
}
section.section-welcome .welcome-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 30px;
  overflow: hidden;
  transition: all 1s;
}
section.section-welcome .welcome-background .background-item {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 1.5s;
}
section.section-welcome .welcome-background .background-item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top center;
}
section.section-welcome .welcome-background .background-item.active {
  opacity: 0.85;
}
section.section-welcome .welcome-background .background-cover {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  opacity: 0.9;
  transition: height 0.8s;
}
section.section-welcome .welcome-background .background-cover.scrolled {
  height: 100%;
  opacity: 1;
}
section.section-welcome .welcome-inner {
  padding-top: 150px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.section-welcome .welcome-inner .inner-description {
  width: 43%;
}
section.section-welcome .welcome-inner .inner-description h1 {
  font-size: 55px;
  color: #000;
  font-weight: 600;
}
section.section-welcome .welcome-inner .inner-description h1 span {
  color: #c31432;
}
section.section-welcome .welcome-inner .inner-description .inner-claim {
  margin-top: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
section.section-welcome .welcome-inner .inner-description .inner-claim > * + * {
  margin-left: 10px;
}
section.section-welcome .welcome-inner .inner-description .inner-claim .claim-slider {
  position: relative;
  transition: all 0.8s;
}
section.section-welcome .welcome-inner .inner-description .inner-claim .claim-slider p {
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  left: 0;
  white-space: nowrap;
  opacity: 0;
}
section.section-welcome .welcome-inner .inner-description .inner-claim .claim-slider p.active {
  opacity: 1;
}
section.section-welcome .welcome-inner .inner-description .inner-claim p {
  color: #555;
  font-weight: 700;
  transition: all 0.8s;
}
section.section-welcome .welcome-inner .inner-description .inner-claim p b {
  font-weight: 700;
  color: #c31432;
}
section.section-welcome .welcome-inner .inner-description .inner-text {
  margin-top: 70px;
}
section.section-welcome .welcome-inner .inner-description .inner-text p {
  margin-top: 35px;
  letter-spacing: 0.2px;
  color: #555;
}
section.section-welcome .welcome-inner .inner-description .inner-text p b {
  font-weight: 700;
  color: #c31432;
}
section.section-welcome .welcome-inner .inner-description .inner-form {
  margin-top: 30px;
  position: relative;
  background: #f6f8f8;
  border-radius: 50px;
  padding: 15px;
  display: flex;
  align-items: flex-end;
}
section.section-welcome .welcome-inner .inner-description .inner-form .input-service {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}
section.section-welcome .welcome-inner .inner-description .inner-form .input-service label {
  font-size: 12px;
  color: #000;
}
section.section-welcome .welcome-inner .inner-description .inner-form .input-service input {
  border: 1px dotted #777;
  border-radius: 50px;
  display: block;
  width: 100%;
  height: 55px;
  outline: none;
  padding: 10px 230px 10px 20px;
  transition: all 0.4s;
}
section.section-welcome .welcome-inner .inner-description .inner-form .input-service input:focus {
  border: 1px dotted #c31432;
}
section.section-welcome .welcome-inner .inner-description .inner-form .btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  text-wrap: nowrap;
}
section.section-welcome .welcome-inner .inner-screen {
  width: 50%;
  padding-top: 39%;
  position: relative;
}
section.section-welcome .welcome-inner .inner-screen .screen-image {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
section.section-welcome .welcome-inner .inner-screen .screen-products {
  width: 45%;
  padding-top: 45%;
  position: absolute;
  top: 25%;
  left: 8.2%;
}
section.section-welcome .welcome-inner .inner-screen .screen-products img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.8s;
}
section.section-welcome .welcome-inner .inner-screen .screen-products img.active {
  opacity: 1;
}
section.section-welcome .welcome-inner .inner-screen .screen-products img.active.out {
  transform: translateX(-30px);
  opacity: 0;
}
section.section-welcome .welcome-inner .inner-screen .screen-products img.out {
  transform: translateX(30px);
}

section.section-text-image.pt {
  padding-top: 140px;
}
section.section-text-image.pb {
  padding-bottom: 140px;
}
section.section-text-image .text-image-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.section-text-image .text-image-inner .inner-image {
  width: 40%;
}
section.section-text-image .text-image-inner .inner-image .image-wrapper {
  border-radius: 30px;
  overflow: hidden;
}
section.section-text-image .text-image-inner .inner-image .image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
section.section-text-image .text-image-inner .inner-text {
  width: 50%;
}
section.section-text-image .text-image-inner .inner-text h2 {
  font-size: 40px;
  color: #000;
  font-weight: 600;
}
section.section-text-image .text-image-inner .inner-text h2 span {
  color: #c31432;
}
section.section-text-image .text-image-inner .inner-text p {
  margin-top: 35px;
  letter-spacing: 0.2px;
  color: #555;
  font-weight: 400;
}
section.section-text-image .text-image-inner .inner-text .text-image {
  display: none;
  width: 100%;
  border-radius: 30px;
  overflow: hidden;
}
section.section-text-image .text-image-inner .inner-text .text-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
section.section-text-image .text-image-inner .inner-text .accordion {
  width: 100%;
  margin-top: 60px;
}
section.section-text-image .text-image-inner .inner-text .accordion .accordion-item .accordion-header .accordion-button {
  font-size: 20px;
  color: #c31432;
  font-weight: 700;
  box-shadow: none;
  text-align: left;
  border: none;
  border-bottom: 0.5px solid #ccc;
  padding: 0 0 10px 0;
  background: none;
}
section.section-text-image .text-image-inner .inner-text .accordion .accordion-item .accordion-header .accordion-button span {
  padding-right: 50px;
}
section.section-text-image .text-image-inner .inner-text .accordion .accordion-item .accordion-header .accordion-button.collapsed {
  color: #000;
}
section.section-text-image .text-image-inner .inner-text .accordion .accordion-item .accordion-collapse {
  border: none;
}
section.section-text-image .text-image-inner .inner-text .accordion .accordion-item .accordion-body {
  padding: 20px 0 0 0;
}
section.section-text-image .text-image-inner .inner-text .accordion .accordion-item .accordion-body p {
  margin-top: 0;
}
section.section-text-image .text-image-inner .inner-text .accordion .accordion-item + .accordion-item {
  margin-top: 20px;
}

section.section-about {
  padding: 140px 0;
}
section.section-about.subpage {
  padding-top: 220px;
}
section.section-about .about-inner {
  position: relative;
  z-index: 555;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}
section.section-about .about-inner .about-shape-1 {
  top: 0;
  right: 0;
  transform: rotate(-45deg) translate(85%, 85%);
  position: absolute;
  height: 150px;
}
section.section-about .about-inner .about-text {
  position: relative;
}
section.section-about .about-inner .about-text h2 {
  font-size: 45px;
  color: #000;
  font-weight: 500;
  margin-bottom: 50px;
}
section.section-about .about-inner .about-text h2 span {
  color: #c31432;
}
section.section-about .about-inner .about-text p {
  max-width: 600px;
  margin: 0 auto;
  font-size: 16px;
  color: #555;
  letter-spacing: 0.2px;
  line-height: 23px;
}
section.section-about .about-inner .inner-cta {
  margin-top: 70px;
  display: flex;
  align-items: center;
}
section.section-about .about-inner .inner-cta p {
  font-weight: 700;
  color: #000;
  letter-spacing: 1px;
  font-size: 17px;
}
section.section-about .about-inner .inner-cta .cta-flexbox {
  margin-left: 50px;
  display: flex;
  align-items: center;
}
section.section-about .about-inner .inner-cta .cta-flexbox .btn + .btn {
  margin-left: 20px;
}

section.section-advantages {
  padding: 100px 0 140px 0;
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(246, 248, 248) 100%);
}
section.section-advantages.subpage {
  padding: 0;
  background: none;
}
section.section-advantages.background {
  padding: 100px 0;
  background: #c31432;
}
section.section-advantages .section-heading {
  padding-left: 50%;
}
section.section-advantages .section-heading h2 {
  font-size: 40px;
  color: #000;
  font-weight: 600;
}
section.section-advantages .section-heading h2 span {
  color: #c31432;
}
section.section-advantages .advantages-inner-wrapper {
  position: relative;
}
section.section-advantages .advantages-inner-wrapper .inner-shape {
  position: absolute;
  top: 0;
  left: 55px;
  width: 300px;
  opacity: 0.2;
  transform: translateY(-100%);
}
section.section-advantages .advantages-inner-wrapper .advantages-inner {
  margin-top: 90px;
  padding: 40px;
  border-radius: 13px;
  background: #fff;
  box-shadow: 10px 20px 40px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  gap: 30px 0;
}
section.section-advantages .advantages-inner-wrapper .advantages-inner .inner-service {
  width: 32%;
  display: flex;
}
section.section-advantages .advantages-inner-wrapper .advantages-inner .inner-service .service-number {
  height: 70px;
  min-width: 70px;
  max-width: 70px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #f6f8f8;
  color: #c31432;
  font-weight: 900;
  font-size: 30px;
}
section.section-advantages .advantages-inner-wrapper .advantages-inner .inner-service .service-text {
  padding-left: 20px;
}
section.section-advantages .advantages-inner-wrapper .advantages-inner .inner-service .service-text h3 {
  font-size: 20px;
  font-weight: 700;
}
section.section-advantages .advantages-inner-wrapper .advantages-inner .inner-service .service-text p {
  margin-top: 20px;
  letter-spacing: 0.2px;
  color: #555;
  font-weight: 400;
}
section.section-advantages .advantages-inner-wrapper .advantages-inner .inner-collapse .collapse-flexbox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.section-advantages .advantages-inner-wrapper .advantages-inner .inner-collapse .collapse-flexbox .inner-service {
  margin-top: 10px;
}
section.section-advantages .collapse-button {
  margin-top: -18px;
  z-index: 111;
  position: relative;
}
section.section-advantages .collapse-button .btn {
  box-shadow: none;
}
section.section-advantages .collapse-button .btn span:before {
  content: "Pokaż więcej";
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
}
section.section-advantages .collapse-button .btn.active span:before {
  content: "Zwiń";
}
section.section-advantages .advantages-cta {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}
section.section-advantages .advantages-cta .cta-shape-1 {
  bottom: 0;
  left: 50%;
  position: absolute;
  height: 170px;
  transition: all 0.4s;
  animation: mover 1s infinite alternate;
}
@keyframes mover {
  0% {
    transform: translateX(-50%) translateY(35%) scale(1);
    opacity: 0.3;
  }
  100% {
    transform: translateX(-50%) translateY(35%) scale(1.13);
    opacity: 1;
  }
}
section.section-advantages .advantages-cta .cta-shape-1.active {
  opacity: 0;
  visibility: hidden;
}
section.section-advantages .advantages-cta .cta-text {
  margin-top: 100px;
  position: relative;
}
section.section-advantages .advantages-cta .cta-text h2 {
  font-size: 45px;
  color: #000;
  font-weight: 500;
  margin-bottom: 20px;
}
section.section-advantages .advantages-cta .cta-text h2 span {
  color: #c31432;
}
section.section-advantages .advantages-cta .cta-text p {
  max-width: 600px;
  margin: 0 auto;
  font-size: 16px;
  color: #555;
  letter-spacing: 0.2px;
  line-height: 23px;
}
section.section-advantages .advantages-cta .cta-text .btn {
  margin-top: 70px;
}
section.section-advantages .advantages-cta .cta-text .btn.active {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-70px);
}
section.section-advantages .advantages-cta .cta-form {
  position: relative;
  background: #f6f8f8;
  border-radius: 30px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  z-index: 555;
  margin: 0 auto;
  max-width: 500px;
  transition: all 0.8s;
}
section.section-advantages .advantages-cta .cta-form .heading {
  width: 100%;
  font-size: 25px;
  color: #000;
  font-weight: 600;
}
section.section-advantages .advantages-cta .cta-form .input-service {
  width: 48%;
  margin-top: 20px;
}
section.section-advantages .advantages-cta .cta-form .input-service label {
  font-size: 12px;
  color: #000;
}
section.section-advantages .advantages-cta .cta-form .input-service input {
  display: block;
  width: 100%;
  height: 45px;
  outline: none;
  border: 1px dotted #000;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  transition: all 0.4s;
}
section.section-advantages .advantages-cta .cta-form .input-service input:focus {
  border: 1px solid #c31432;
}
section.section-advantages .advantages-cta .cta-form .btn {
  margin-top: 30px;
  width: 100%;
}
section.section-advantages .advantages-cta .cta-form.active {
  margin-top: -45px;
}
section.section-advantages .advantages-cta.wide {
  max-width: 600px;
}
section.section-advantages .advantages-cta.wide .cta-form {
  max-width: 100%;
}
section.section-advantages .advantages-cta.wide-xl {
  max-width: 1000px;
}
section.section-advantages .advantages-cta.wide-xl .cta-form {
  max-width: 100%;
}

section.section-design .design-inner {
  padding-left: 100px;
  display: flex;
}
section.section-design .design-inner .inner-description {
  padding: 140px 0;
  max-width: 600px;
  position: relative;
  z-index: 1;
}
section.section-design .design-inner .inner-description .description-shape {
  position: absolute;
  bottom: 0;
  transform: translateY(20%) translateX(-100%);
  left: 50px;
  width: 400px;
}
section.section-design .design-inner .inner-description h2 {
  font-size: 40px;
  color: #000;
  font-weight: 600;
  margin-bottom: 50px;
  position: relative;
}
section.section-design .design-inner .inner-description h2 span {
  color: #c31432;
}
section.section-design .design-inner .inner-description p {
  letter-spacing: 0.2px;
  color: #555;
  font-weight: 400;
  position: relative;
}
section.section-design .design-inner .inner-description .btn {
  position: relative;
  margin-left: 100px;
  margin-top: 50px;
}
section.section-design .design-inner .inner-description .description-text {
  padding-left: 100px;
}
section.section-design .design-inner .inner-image {
  position: relative;
}
section.section-design .design-inner .inner-image img {
  position: absolute;
  left: 0;
  top: -50px;
  height: calc(100% + 120px);
}

section.section-skins .skins-inner {
  border-radius: 30px;
  padding: 100px 0 70px 0;
  background: #f6f8f8;
}
section.section-skins .section-heading {
  text-align: center;
}
section.section-skins .section-heading h2 {
  font-size: 40px;
  color: #000;
  font-weight: 600;
  margin-bottom: 20px;
}
section.section-skins .section-heading h2 span {
  color: #c31432;
}
section.section-skins .section-heading p {
  max-width: 500px;
  width: 95%;
  margin: 0 auto;
  letter-spacing: 0.2px;
  color: #555;
  font-weight: 400;
}
section.section-skins .swiper-outer {
  margin-top: 30px;
  position: relative;
}
section.section-skins .swiper-outer .outer-cover {
  z-index: 111;
  pointer-events: none;
  position: absolute;
  top: 0;
  height: 100%;
}
section.section-skins .swiper-outer .outer-cover.left {
  left: 0;
  width: 400px;
  background: linear-gradient(90deg, rgb(246, 248, 248) 0%, rgba(246, 248, 248, 0) 100%);
}
section.section-skins .swiper-outer .outer-cover.right {
  right: 0;
  width: 400px;
  background: linear-gradient(-90deg, rgb(246, 248, 248) 0%, rgba(246, 248, 248, 0) 100%);
}
section.section-skins .swiper-skins .swiper-slide {
  width: auto;
}
section.section-skins .swiper-skins .skin-item {
  outline: none;
  display: block;
  width: 350px;
  padding: 40px 40px 0 40px;
}
section.section-skins .swiper-skins .skin-item.premium:after {
  content: "Premium";
  position: absolute;
  top: 45px;
  left: 50%;
  padding: 5px 12px;
  color: #333;
  font-weight: 700;
  border-radius: 5px;
  font-size: 12px;
  background: gold;
  transform: translate(-50%, -50%);
}
section.section-skins .swiper-skins .skin-item .service-image {
  width: 100%;
  padding-top: 100%;
  border-radius: 20px;
  box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  transition: all 0.5s;
  overflow: hidden;
}
section.section-skins .swiper-skins .skin-item .service-image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
section.section-skins .swiper-skins .skin-item .service-number {
  color: #000;
  opacity: 0.15;
  font-weight: 900;
  font-size: 70px;
  line-height: 67px;
  text-align: center;
}
section.section-skins .swiper-skins .skin-item .service-heading {
  margin-top: 35px;
  text-align: center;
  color: #000;
  font-size: 20px;
  font-weight: 700;
}
section.section-skins .swiper-skins .skin-item .service-description {
  margin-top: 15px;
  text-align: center;
  font-size: 16px;
  color: #555;
  letter-spacing: 0.2px;
  line-height: 23px;
  position: relative;
}

section.section-cooperate {
  padding: 100px 0;
}
section.section-cooperate .section-heading {
  text-align: center;
}
section.section-cooperate .section-heading h2 {
  font-size: 40px;
  color: #000;
  font-weight: 600;
  margin-bottom: 20px;
}
section.section-cooperate .section-heading h2 span {
  color: #c31432;
}
section.section-cooperate .section-heading p {
  max-width: 500px;
  margin: 0 auto;
  letter-spacing: 0.2px;
  color: #555;
  font-weight: 400;
}
section.section-cooperate .swiper-outer {
  margin-top: 40px;
  position: relative;
}
section.section-cooperate .swiper-outer .outer-cover {
  z-index: 111;
  pointer-events: none;
  position: absolute;
  top: 0;
  height: 100%;
}
section.section-cooperate .swiper-outer .outer-cover.left {
  left: 0;
  width: 300px;
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
}
section.section-cooperate .swiper-outer .outer-cover.right {
  right: 0;
  width: 300px;
  background: linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
}
section.section-cooperate .swiper-cooperate .swiper-wrapper {
  align-items: center;
}
section.section-cooperate .swiper-cooperate .swiper-slide {
  width: auto;
}
section.section-cooperate .swiper-cooperate .cooperate-item {
  outline: none;
  display: block;
  width: 150px;
}
section.section-cooperate .swiper-cooperate .cooperate-item img {
  width: 100%;
}
section.section-cooperate .swiper-cooperate .cooperate-item.w-1 {
  width: 140px;
}
section.section-cooperate .swiper-cooperate .cooperate-item.w-2 {
  width: 130px;
}
section.section-cooperate .swiper-cooperate .cooperate-item.w-3 {
  width: 120px;
}
section.section-cooperate .swiper-cooperate .cooperate-item.w-4 {
  width: 110px;
}

section.section-form.pt {
  padding-top: 100px;
}
section.section-form.pb {
  padding-bottom: 100px;
}
section.section-form .form-inner {
  padding: 70px;
  border-radius: 30px;
  background: rgb(195, 20, 50);
  background: linear-gradient(90deg, rgb(195, 20, 50) 0%, rgb(132, 10, 31) 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.section-form .form-inner .inner-text {
  width: 47%;
}
section.section-form .form-inner .inner-text h2 {
  font-size: 40px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 20px;
}
section.section-form .form-inner .inner-text p {
  max-width: 500px;
  letter-spacing: 0.2px;
  color: #eee;
  font-weight: 400;
}
section.section-form .form-inner .inner-text .text-contact {
  margin-top: 40px;
  display: flex;
  align-items: center;
}
section.section-form .form-inner .inner-text .text-contact > * + * {
  margin-left: 30px;
}
section.section-form .form-inner .inner-text .text-contact .contact-service {
  display: flex;
  align-items: center;
}
section.section-form .form-inner .inner-text .text-contact .contact-service img {
  margin-right: 8px;
}
section.section-form .form-inner .inner-text .text-contact .contact-service a {
  font-size: 26px;
  color: #fff;
  font-weight: 600;
}
section.section-form .form-inner .inner-text .text-contact .contact-service:nth-child(2) a {
  transform: translateY(-3px);
}
section.section-form .form-inner .inner-form {
  width: 50%;
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  padding: 15px;
  display: flex;
  align-items: flex-end;
}
section.section-form .form-inner .inner-form .input-service {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}
section.section-form .form-inner .inner-form .input-service label {
  font-size: 12px;
  color: #000;
}
section.section-form .form-inner .inner-form .input-service input {
  border: 1px dotted rgba(255, 255, 255, 0.4);
  border-radius: 50px;
  display: block;
  width: 100%;
  height: 55px;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px 230px 10px 20px;
  transition: all 0.4s;
  color: #fff;
}
section.section-form .form-inner .inner-form .input-service input::placeholder {
  color: #ddd;
}
section.section-form .form-inner .inner-form .input-service input:focus {
  border: 1px dotted rgb(255, 255, 255);
}
section.section-form .form-inner .inner-form .btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  text-wrap: nowrap;
}

section.section-pricing {
  padding: 100px 0;
}
section.section-pricing .section-heading-wrapper {
  display: flex;
  justify-content: space-between;
}
section.section-pricing .section-heading-wrapper .section-heading {
  text-align: left;
}
section.section-pricing .section-heading-wrapper .pricing-clock-wrapper {
  margin-top: 0;
}
section.section-pricing .section-heading-wrapper .pricing-clock-wrapper .pricing-clock-heading {
  text-align: right;
}
section.section-pricing .section-heading-wrapper .pricing-clock-wrapper .pricing-clock {
  justify-content: flex-end;
  margin-top: 15px;
}
section.section-pricing .section-heading {
  text-align: center;
}
section.section-pricing .section-heading h2 {
  font-size: 40px;
  color: #000;
  font-weight: 600;
  margin-bottom: 20px;
}
section.section-pricing .section-heading h2 span {
  color: #c31432;
}
section.section-pricing .section-heading p {
  max-width: 500px;
  margin: 0 auto;
  letter-spacing: 0.2px;
  color: #555;
  font-weight: 400;
}
section.section-pricing .pricing-clock-wrapper {
  margin-top: 50px;
}
section.section-pricing .pricing-clock-wrapper .pricing-clock-heading {
  text-align: center;
}
section.section-pricing .pricing-clock-wrapper .pricing-clock-heading h3 {
  font-size: 20px;
  color: #000;
  font-weight: 700;
}
section.section-pricing .pricing-clock-wrapper .pricing-clock-heading h3 span {
  color: #c31432;
}
section.section-pricing .pricing-clock-wrapper .pricing-clock-heading p {
  max-width: 600px;
  margin: 0 auto;
  font-size: 16px;
  color: #555;
  letter-spacing: 0.2px;
  line-height: 23px;
}
section.section-pricing .pricing-clock-wrapper .pricing-clock {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.section-pricing .pricing-clock-wrapper .pricing-clock .clock-service {
  text-align: center;
}
section.section-pricing .pricing-clock-wrapper .pricing-clock .clock-service .value {
  background: #c31432;
  color: #fff;
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  font-size: 28px;
  font-weight: 500;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.section-pricing .pricing-clock-wrapper .pricing-clock .clock-service .name {
  margin-top: 3px;
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  font-weight: 500;
}
section.section-pricing .pricing-clock-wrapper .pricing-clock .clock-service + .clock-service {
  margin-left: 15px;
}
section.section-pricing .table-wrapper {
  margin-top: 40px;
  max-height: 650px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 110px 50px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  transition: all 0.8s;
}
section.section-pricing .table-wrapper .wrapper-cover {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 280px;
  background: rgb(211, 211, 209);
  background: linear-gradient(180deg, rgba(230, 230, 228, 0) 0%, rgb(211, 211, 209) 100%);
  transition: all 0.8s;
}
section.section-pricing .table-wrapper.active {
  max-height: 4500px;
}
section.section-pricing .table-wrapper.active .wrapper-cover {
  opacity: 0;
  visibility: hidden;
  box-shadow: 0px 90px 30px 0px rgba(0, 0, 0, 0);
}
section.section-pricing .table-responsive {
  overflow-x: hidden;
}
section.section-pricing table {
  margin-top: 90px;
  margin-bottom: 0;
  border-radius: 15px;
}
section.section-pricing table tbody tr td {
  vertical-align: middle;
  border: none;
  text-align: center;
}
section.section-pricing table tbody tr td.bestseller {
  position: relative;
}
section.section-pricing table tbody tr td.bestseller:before {
  content: "Najpopularniejszy";
  text-wrap: nowrap;
  font-weight: 500;
  padding: 3px 10px;
  border-radius: 20px;
  background: #c31432;
  color: #fff;
  font-size: 12px;
  letter-spacing: 1px;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -100%);
}
section.section-pricing table tbody tr td.later {
  position: relative;
}
section.section-pricing table tbody tr td.later:before {
  content: "Już wkrótce";
  text-wrap: nowrap;
  font-weight: 500;
  padding: 3px 10px;
  border-radius: 20px;
  background: #000;
  color: #fff;
  font-size: 12px;
  letter-spacing: 1px;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -100%);
}
section.section-pricing table tbody tr td .heading {
  padding-top: 3px;
  color: #000;
  font-weight: 700;
  font-size: 19px;
}
section.section-pricing table tbody tr td .heading strong {
  color: #c31432;
}
section.section-pricing table tbody tr td .price-heading {
  margin-top: 10px;
  color: #000;
  font-size: 13.5px;
}
section.section-pricing table tbody tr td .price {
  font-weight: 700;
  font-size: 35px;
  color: #000;
  margin-top: 5px;
  line-height: 31px;
  transform: translateX(19px);
}
section.section-pricing table tbody tr td .price.red {
  color: #c31432;
  margin-top: 10px;
  font-size: 45.5px;
  line-height: 40.3px;
}
section.section-pricing table tbody tr td .price span {
  color: inherit;
}
section.section-pricing table tbody tr td .price small {
  font-size: 14px;
  font-weight: 300;
  color: #000;
}
section.section-pricing table tbody tr td .price.promotion-active {
  position: relative;
  color: #888;
  transform: scale(0.7) translateX(19px);
}
section.section-pricing table tbody tr td .price.promotion-active:after {
  content: "";
  width: 40%;
  height: 2px;
  background: #c31432;
  position: absolute;
  top: 10px;
  left: 23%;
  transform: rotate(-5deg);
}
section.section-pricing table tbody tr td .price-promotion {
  font-weight: 700;
  font-size: 35px;
  color: #c31432;
  margin-top: 5px;
  line-height: 31px;
  transform: translateX(19px);
}
section.section-pricing table tbody tr td .price-promotion span {
  color: inherit;
}
section.section-pricing table tbody tr td .price-promotion small {
  font-size: 14px;
  font-weight: 300;
  color: #c31432;
}
section.section-pricing table tbody tr td .price-omnibus {
  font-size: 10px;
}
section.section-pricing table tbody tr td img {
  height: 20px;
}
section.section-pricing table tbody tr td span {
  color: olivedrab;
}
section.section-pricing table tbody tr td .value {
  position: relative;
}
section.section-pricing table tbody tr td .value.info {
  cursor: pointer;
}
section.section-pricing table tbody tr td .value.info:after {
  content: "";
  height: 10px;
  width: 10px;
  background: url("../img/icons/information.svg") center;
  background-size: cover;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-100%, -50%);
}
section.section-pricing table tbody tr td .value.alert-dot:before {
  content: "Już wkrótce";
  font-size: 10px;
  background: #000;
  color: #fff;
  font-weight: 500;
  line-height: 10px;
  display: block;
  width: 70px;
  margin: 0 auto;
  padding: 3px 0 2px 0;
  border-radius: 20px;
}
section.section-pricing table tbody tr td .table-form .input-service label {
  font-size: 12px;
  color: #000;
}
section.section-pricing table tbody tr td .table-form .input-service .service-input-wrapper {
  position: relative;
}
section.section-pricing table tbody tr td .table-form .input-service .service-input-wrapper svg {
  position: absolute;
  left: 15px;
  top: 50%;
  height: 16px;
  width: 16px;
  fill: #000;
  transform: translateY(-50%);
}
section.section-pricing table tbody tr td .table-form .input-service .service-input-wrapper input {
  display: block;
  width: 100%;
  height: 38px;
  outline: none;
  border: 1px dotted #000;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  transition: all 0.4s;
}
section.section-pricing table tbody tr td .table-form .input-service .service-input-wrapper input:focus {
  border: 1px solid #c31432;
}
section.section-pricing table tbody tr td .table-form .input-service + .input-service {
  margin-top: 15px;
}
section.section-pricing table tbody tr td .table-form .checkbox-wrapper span {
  color: #000;
}
section.section-pricing table tbody tr td .table-form .btn {
  width: 100%;
  margin-top: 20px;
}
section.section-pricing table tbody tr td:nth-child(1) {
  width: 35%;
}
section.section-pricing table tbody tr td:nth-child(3) {
  background: #F9F1F1;
}
section.section-pricing table tbody tr td:nth-child(2), section.section-pricing table tbody tr td:nth-child(3), section.section-pricing table tbody tr td:nth-child(4) {
  width: 65%/3;
}
section.section-pricing table tbody tr:not(:first-child) td {
  border-top: 0.7px solid rgba(0, 0, 0, 0.05);
}
section.section-pricing table tbody tr:first-child td:first-child {
  border-radius: 15px 0 0 0;
}
section.section-pricing table tbody tr:first-child td:nth-child(3) {
  border-radius: 15px 15px 0 0;
}
section.section-pricing table tbody tr:last-child td {
  padding: 20px;
}
section.section-pricing table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 15px;
}
section.section-pricing table tbody tr:last-child td:nth-child(3) {
  border-radius: 0 0 15px 15px;
}
section.section-pricing .btn-pricing-more {
  text-align: center;
  margin-top: -20px;
  position: relative;
}
section.section-pricing .btn-pricing-more .btn {
  box-shadow: none;
  transition: all 0.8s;
}
section.section-pricing .btn-pricing-more .btn span:before {
  content: "Pokaż wszystkie funkcje";
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
}
section.section-pricing .btn-pricing-more .btn.active {
  transform: translateY(35px);
}
section.section-pricing .btn-pricing-more .btn.active span:before {
  content: "Zwiń";
}
section.section-pricing .pricing-cta {
  position: relative;
  text-align: center;
}
section.section-pricing .pricing-cta .cta-shape-1 {
  bottom: 0;
  left: 50%;
  position: absolute;
  height: 170px;
  transition: all 0.4s;
  animation: mover 1s infinite alternate;
}
section.section-pricing .pricing-cta .cta-text {
  margin-top: 130px;
  position: relative;
}
section.section-pricing .pricing-cta .cta-text h2 {
  font-size: 40px;
  color: #000;
  font-weight: 600;
  margin-bottom: 20px;
}
section.section-pricing .pricing-cta .cta-text h2 span {
  color: #c31432;
}
section.section-pricing .pricing-cta .cta-text p {
  max-width: 600px;
  margin: 0 auto;
  letter-spacing: 0.2px;
  color: #555;
  font-weight: 400;
}
section.section-pricing .pricing-cta .cta-text .btn {
  margin-top: 70px;
}

section.section-shop-test {
  padding: 140px 0;
  background: #c31432;
  position: relative;
}
section.section-shop-test .shop-test-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
}
section.section-shop-test .shop-test-image .image-cover {
  background: linear-gradient(-90deg, rgba(195, 20, 50, 0) 0%, rgb(195, 20, 50) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 65%;
  height: 100%;
}
section.section-shop-test .shop-test-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: left center;
}
section.section-shop-test .shop-test-inner {
  width: 50%;
  position: relative;
}
section.section-shop-test .shop-test-inner .inner-text h2 {
  max-width: 600px;
  font-size: 45px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 20px;
}
section.section-shop-test .shop-test-inner .inner-text p {
  max-width: 600px;
  font-size: 16px;
  color: #fff;
  letter-spacing: 0.2px;
  line-height: 23px;
}
section.section-shop-test .shop-test-inner .inner-text .btn {
  margin-top: 50px;
}

section.section-marketing {
  padding: 140px 0;
}
section.section-marketing .marketing-inner {
  position: relative;
  display: flex;
  justify-content: center;
}
section.section-marketing .marketing-inner .inner-description {
  position: relative;
}
section.section-marketing .marketing-inner .inner-description .description-shape {
  position: absolute;
  bottom: -50px;
  transform: translateX(-100%);
  left: 100px;
  width: 400px;
}
section.section-marketing .marketing-inner .inner-description h2 {
  font-size: 45px;
  color: #000;
  font-weight: 500;
  margin-bottom: 50px;
  position: relative;
}
section.section-marketing .marketing-inner .inner-description h2 span {
  color: #c31432;
}
section.section-marketing .marketing-inner .inner-description p {
  font-size: 16px;
  color: #555;
  letter-spacing: 0.2px;
  line-height: 23px;
  position: relative;
}
section.section-marketing .marketing-inner .inner-description .btn {
  margin-top: 50px;
}
section.section-marketing .marketing-inner .inner-description .description-text {
  max-width: 1000px;
  padding: 50px;
  border-radius: 15px;
  box-shadow: 10px 20px 50px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  position: relative;
}
section.section-marketing .marketing-inner .inner-description .description-text .text-shape {
  position: absolute;
  top: 0;
  right: 55px;
  width: 300px;
  opacity: 0.2;
  transform: translateY(-100%);
}
section.section-marketing .marketing-inner .inner-description .description-text .text-service {
  width: 45%;
}
section.section-marketing .marketing-inner .inner-description .description-text h3 {
  font-size: 20px;
  color: #000;
  font-weight: 500;
  margin-bottom: 10px;
}
section.section-marketing .marketing-inner .inner-description .description-text h3 span {
  color: #c31432;
}

section.section-faq {
  padding: 100px 0 0 0;
  position: relative;
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(246, 248, 248) 100%);
}
section.section-faq .faq-inner {
  display: flex;
}
section.section-faq .faq-inner .inner-image {
  margin-right: 150px;
  min-width: 300px;
  max-width: 300px;
  position: relative;
}
section.section-faq .faq-inner .inner-image img {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 70%;
  max-height: 420px;
}
section.section-faq .faq-inner .inner-accordion {
  position: relative;
  width: 100%;
}
section.section-faq .faq-inner .inner-accordion .accordion-shape {
  position: absolute;
  bottom: 0;
  transform: translateY(20%) translateX(-100%);
  left: 50px;
  width: 400px;
}
section.section-faq .faq-inner .inner-accordion h2 {
  font-size: 40px;
  color: #000;
  font-weight: 600;
  margin-bottom: 50px;
  position: relative;
}
section.section-faq .faq-inner .inner-accordion h2 span {
  color: #c31432;
}
section.section-faq .faq-inner .inner-accordion .accordion {
  width: 100%;
}
section.section-faq .faq-inner .inner-accordion .accordion .accordion-item .accordion-header .accordion-button {
  font-size: 20px;
  color: #c31432;
  font-weight: 700;
  box-shadow: none;
  text-align: left;
  border: none;
  border-bottom: 0.5px solid #ccc;
  padding: 0 0 10px 0;
  background: none;
}
section.section-faq .faq-inner .inner-accordion .accordion .accordion-item .accordion-header .accordion-button span {
  padding-right: 50px;
}
section.section-faq .faq-inner .inner-accordion .accordion .accordion-item .accordion-header .accordion-button.collapsed {
  color: #000;
}
section.section-faq .faq-inner .inner-accordion .accordion .accordion-item .accordion-collapse {
  border: none;
}
section.section-faq .faq-inner .inner-accordion .accordion .accordion-item .accordion-body {
  padding: 20px 0 0 0;
}
section.section-faq .faq-inner .inner-accordion .accordion .accordion-item .accordion-body p {
  letter-spacing: 0.2px;
  color: #555;
  font-weight: 400;
}
section.section-faq .faq-inner .inner-accordion .accordion .accordion-item .accordion-body p a {
  color: #c31432;
  text-decoration: underline;
}
section.section-faq .faq-inner .inner-accordion .accordion .accordion-item + .accordion-item {
  margin-top: 20px;
}
section.section-faq .faq-inner .inner-accordion .btn {
  margin-top: 50px;
}

section.section-payment-partner {
  background: #e2e2e2;
  background: #072653;
  padding: 140px 0;
  position: relative;
}
section.section-payment-partner .payment-partner-background {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  opacity: 0.3;
}
section.section-payment-partner .payment-partner-inner {
  position: relative;
  display: flex;
  align-items: center;
}
section.section-payment-partner .payment-partner-inner .inner-description h2 {
  font-size: 45px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 20px;
}
section.section-payment-partner .payment-partner-inner .inner-description h2 span {
  color: #c31432;
}
section.section-payment-partner .payment-partner-inner .inner-description p {
  max-width: 600px;
  font-size: 16px;
  color: #fff;
  letter-spacing: 0.2px;
  line-height: 23px;
  font-weight: 300;
}
section.section-payment-partner .payment-partner-inner .inner-description p strong {
  font-weight: 800;
}
section.section-payment-partner .payment-partner-inner .inner-description .btn {
  margin-top: 50px;
}
section.section-payment-partner .payment-partner-inner .inner-image {
  padding-left: 70px;
}
section.section-payment-partner .payment-partner-inner .inner-image .image-text {
  padding-left: 195px;
  margin-bottom: 20px;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
}
section.section-payment-partner .payment-partner-inner .inner-image .image-text span {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 300;
}
section.section-payment-partner .payment-partner-inner .inner-image img {
  height: 100px;
}

section.section-thank-you {
  background: #f8f8f8;
  overflow: hidden;
  position: relative;
}
section.section-thank-you .inner-shape-3 {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 400px;
}
section.section-thank-you .thank-you-inner {
  padding: 80px 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
section.section-thank-you .thank-you-inner .inner-shape-1 {
  position: absolute;
  top: 0;
  left: 50%;
  width: 250px;
  transform: translate(-50%, -50%);
  animation: thank-mover 1s infinite alternate;
}
@keyframes thank-mover {
  0% {
    transform: translateX(-50%) translateY(-50%) scale(1);
  }
  100% {
    transform: translateX(-50%) translateY(-50%) scale(1.13);
  }
}
section.section-thank-you .thank-you-inner .inner-shape-2 {
  position: absolute;
  top: 10%;
  left: -300px;
  width: 500px;
}
section.section-thank-you .thank-you-inner .inner-logo {
  text-align: center;
  font-weight: 500;
  font-size: 50px;
  font-weight: 600;
  color: #000;
  transition: all 0.4s;
}
section.section-thank-you .thank-you-inner .inner-logo span {
  color: #c31432;
  font-size: 80px;
  line-height: 10px;
}
section.section-thank-you .thank-you-inner .inner-description {
  margin-top: 30px;
  text-align: center;
  max-width: 500px;
}
section.section-thank-you .thank-you-inner .inner-description p {
  font-size: 26px;
  color: #000;
}
section.section-thank-you .thank-you-inner .inner-cta {
  margin-top: 100px;
  text-align: center;
}
section.section-thank-you .thank-you-inner .inner-cta p {
  color: #000;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  font-weight: 400;
}
section.section-thank-you .thank-you-inner .inner-cta img {
  height: 50px;
}

section.static-page-section-content {
  padding: 250px 0 0 0;
  transition: all 0.4s;
}
section.static-page-section-content .content-inner {
  max-width: 770px;
  width: 100%;
  margin: 0 auto;
}
section.static-page-section-content .content-inner p, section.static-page-section-content .content-inner li {
  color: #000;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.3px;
}
section.static-page-section-content .content-inner ol, section.static-page-section-content .content-inner ul {
  padding-left: 17px;
}
section.static-page-section-content .content-inner h1 {
  font-size: 45px;
  color: #000;
  font-weight: 500;
}
section.static-page-section-content .content-inner h2 {
  font-size: 28px;
  color: #000;
  font-weight: 500;
}
section.static-page-section-content .content-inner h3 {
  font-size: 24px;
  color: #000;
  font-weight: 500;
}
section.static-page-section-content .content-inner h4 {
  font-size: 21px;
  color: #000;
  font-weight: 500;
}
section.static-page-section-content .content-inner h5 {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}
section.static-page-section-content .content-inner h6 {
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

section.formularz-section-content {
  position: relative;
}
section.formularz-section-content .content-close {
  position: absolute;
  top: 30px;
  right: 30px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.formularz-section-content .content-close img {
  height: 15px;
  width: 15px;
}
section.formularz-section-content .content-inner {
  padding: 30px 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
section.formularz-section-content .content-inner .section-heading {
  text-align: center;
  margin-bottom: 30px;
}
section.formularz-section-content .content-inner .section-heading h2 {
  font-size: 40px;
  color: #000;
  font-weight: 600;
}
section.formularz-section-content .content-inner .section-heading h2 span {
  color: #c31432;
}

section.centrum-pomocy-section-content {
  padding-top: 200px;
}
section.centrum-pomocy-section-content .section-heading {
  text-align: center;
  margin-bottom: 50px;
}
section.centrum-pomocy-section-content .section-heading h1 {
  font-size: 55px;
  color: #000;
  font-weight: 600;
}
section.centrum-pomocy-section-content .section-heading h1 span {
  color: #c31432;
}
section.centrum-pomocy-section-content .content-flexbox {
  display: flex;
  flex-wrap: wrap;
}
section.centrum-pomocy-section-content .content-flexbox .flexbox-service {
  width: 33.3333333333%;
  padding: 40px;
  position: relative;
}
section.centrum-pomocy-section-content .content-flexbox .flexbox-service .service-heading {
  text-align: center;
  font-size: 25px;
  color: #000;
  font-weight: 500;
}
section.centrum-pomocy-section-content .content-flexbox .flexbox-service ul {
  margin-top: 25px;
}
section.centrum-pomocy-section-content .content-flexbox .flexbox-service ul li button, section.centrum-pomocy-section-content .content-flexbox .flexbox-service ul li a {
  border: none;
  font-size: 17px;
  font-weight: 500;
  color: #c31432;
  background: none;
  letter-spacing: 1px;
  display: block;
  width: 100%;
  text-align: center;
  position: relative;
  outline: none;
  transition: all 0.3s;
}
section.centrum-pomocy-section-content .content-flexbox .flexbox-service ul li button:hover, section.centrum-pomocy-section-content .content-flexbox .flexbox-service ul li a:hover {
  color: #c31432;
}
section.centrum-pomocy-section-content .content-flexbox .flexbox-service ul li + li {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 0.5px dotted #ccc;
}
section.centrum-pomocy-section-content .content-flexbox .flexbox-service:after {
  content: "";
  height: 100%;
  width: 1px;
  border-right: 1px dotted #c31432;
  position: absolute;
  top: 0;
  right: 0;
}
section.centrum-pomocy-section-content .content-flexbox .flexbox-service:before {
  content: "";
  height: 1px;
  width: 100%;
  border-bottom: 1px dotted #c31432;
  position: absolute;
  top: 0;
  left: 0;
}
section.centrum-pomocy-section-content .content-flexbox .flexbox-service:nth-child(3n):after {
  content: none;
}
section.centrum-pomocy-section-content .content-flexbox .flexbox-service:nth-child(1):before, section.centrum-pomocy-section-content .content-flexbox .flexbox-service:nth-child(2):before, section.centrum-pomocy-section-content .content-flexbox .flexbox-service:nth-child(3):before {
  content: none;
}

section.dodatkowe-skorki-section-content {
  padding-top: 200px;
}
section.dodatkowe-skorki-section-content .section-heading {
  text-align: center;
  margin-bottom: 50px;
}
section.dodatkowe-skorki-section-content .section-heading h1 {
  font-size: 45px;
  color: #000;
  font-weight: 500;
  margin-bottom: 20px;
}
section.dodatkowe-skorki-section-content .section-heading h1 span {
  color: #c31432;
}
section.dodatkowe-skorki-section-content .section-heading p {
  max-width: 600px;
  margin: 0 auto;
  font-size: 16px;
  color: #555;
  letter-spacing: 0.2px;
  line-height: 23px;
}
section.dodatkowe-skorki-section-content .content-flexbox {
  display: flex;
  flex-wrap: wrap;
}
section.dodatkowe-skorki-section-content .content-flexbox .flexbox-service {
  width: 48%;
}
section.dodatkowe-skorki-section-content .content-flexbox .flexbox-service .service-image img {
  width: 100%;
}
section.dodatkowe-skorki-section-content .content-flexbox .flexbox-service .service-heading {
  text-align: center;
  font-size: 25px;
  color: #000;
  font-weight: 500;
}

section.testuj-sklep-section-content {
  padding-top: 200px;
  padding-bottom: 100px;
  position: relative;
}
section.testuj-sklep-section-content .content-background {
  position: absolute;
  top: 115px;
  right: 0;
  height: calc(100% - 115px);
  width: 55%;
}
section.testuj-sklep-section-content .content-background .background-cover {
  background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 65%;
  height: 100%;
}
section.testuj-sklep-section-content .content-background img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center top;
}
section.testuj-sklep-section-content .section-heading {
  margin-bottom: 50px;
  width: 50%;
  max-width: 600px;
  position: relative;
}
section.testuj-sklep-section-content .section-heading h1 {
  font-size: 45px;
  color: #000;
  font-weight: 500;
  margin-bottom: 20px;
}
section.testuj-sklep-section-content .section-heading h1 span {
  color: #c31432;
}
section.testuj-sklep-section-content .section-heading p {
  font-size: 16px;
  color: #555;
  letter-spacing: 0.2px;
  line-height: 23px;
}
section.testuj-sklep-section-content .content-inner {
  margin-top: 80px;
  width: 60%;
  display: flex;
  justify-content: space-between;
  position: relative;
}
section.testuj-sklep-section-content .content-inner .inner-advantages {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
section.testuj-sklep-section-content .content-inner .inner-advantages .advantage-service {
  padding: 15px;
  background: #c31432;
  border-radius: 10px;
  text-align: center;
}
section.testuj-sklep-section-content .content-inner .inner-advantages .advantage-service .name {
  font-size: 25px;
  line-height: 25px;
  color: #fff;
  font-weight: 500;
}
section.testuj-sklep-section-content .content-inner .inner-advantages .advantage-service .text {
  margin-top: 8px;
  font-size: 16px;
  color: #fff;
  letter-spacing: 0.2px;
  line-height: 16px;
}
section.testuj-sklep-section-content .content-inner .inner-form {
  width: 65%;
  position: relative;
  background: #fff;
  box-shadow: 10px 20px 50px 0px rgba(0, 0, 0, 0.15);
  border-radius: 13px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  z-index: 555;
  transition: all 0.8s;
}
section.testuj-sklep-section-content .content-inner .inner-form .heading {
  width: 100%;
  font-size: 25px;
  color: #000;
  font-weight: 500;
}
section.testuj-sklep-section-content .content-inner .inner-form .input-service {
  width: 48%;
  margin-top: 20px;
}
section.testuj-sklep-section-content .content-inner .inner-form .input-service label {
  font-size: 12px;
  color: #000;
}
section.testuj-sklep-section-content .content-inner .inner-form .input-service input {
  display: block;
  width: 100%;
  height: 45px;
  outline: none;
  border: 1px dotted #000;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  transition: all 0.4s;
}
section.testuj-sklep-section-content .content-inner .inner-form .input-service input:focus {
  border: 1px solid #c31432;
}
section.testuj-sklep-section-content .content-inner .inner-form .btn {
  margin-top: 30px;
  width: 100%;
}

section.uruchom-sklep-section-content {
  padding-top: 200px;
  padding-bottom: 100px;
  position: relative;
}
section.uruchom-sklep-section-content .content-background {
  position: absolute;
  top: 115px;
  right: 0;
  height: calc(100% - 115px);
  width: 55%;
}
section.uruchom-sklep-section-content .content-background .background-cover {
  background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 65%;
  height: 100%;
}
section.uruchom-sklep-section-content .content-background img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center top;
}
section.uruchom-sklep-section-content .section-heading {
  width: 50%;
  max-width: 600px;
  position: relative;
}
section.uruchom-sklep-section-content .section-heading h1 {
  font-size: 65px;
  color: #000;
  font-weight: 500;
}
section.uruchom-sklep-section-content .section-heading h1 span {
  color: #c31432;
}
section.uruchom-sklep-section-content .content-inner {
  margin-top: 30px;
  width: 60%;
  position: relative;
}
section.uruchom-sklep-section-content .content-inner .inner-advantages {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px 0;
}
section.uruchom-sklep-section-content .content-inner .inner-advantages .advantage-service {
  width: 30%;
  padding: 15px;
  background: #c31432;
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
section.uruchom-sklep-section-content .content-inner .inner-advantages .advantage-service .name {
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  font-weight: 500;
}
section.uruchom-sklep-section-content .content-inner .inner-description {
  margin-top: 50px;
  width: 80%;
}
section.uruchom-sklep-section-content .content-inner .inner-description p {
  font-size: 20px;
  color: #555;
  letter-spacing: 0.2px;
  line-height: 26px;
}
section.uruchom-sklep-section-content .content-inner .inner-description p b {
  font-weight: 700;
  color: #c31432;
}
section.uruchom-sklep-section-content .content-inner .inner-form {
  margin-top: 50px;
  position: relative;
  background: #fff;
  box-shadow: 10px 20px 50px 0px rgba(0, 0, 0, 0.15);
  border-radius: 13px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  z-index: 555;
  transition: all 0.8s;
}
section.uruchom-sklep-section-content .content-inner .inner-form .heading {
  width: 100%;
  font-size: 25px;
  color: #000;
  font-weight: 500;
}
section.uruchom-sklep-section-content .content-inner .inner-form .input-service {
  width: 23%;
  margin-top: 20px;
}
section.uruchom-sklep-section-content .content-inner .inner-form .input-service label {
  font-size: 12px;
  color: #000;
}
section.uruchom-sklep-section-content .content-inner .inner-form .input-service input {
  display: block;
  width: 100%;
  height: 45px;
  outline: none;
  border: 1px dotted #000;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  transition: all 0.4s;
}
section.uruchom-sklep-section-content .content-inner .inner-form .input-service input:focus {
  border: 1px solid #c31432;
}
section.uruchom-sklep-section-content .content-inner .inner-form .btn {
  margin-top: 30px;
  width: 100%;
}

section.section-footer {
  padding-top: 100px;
}
section.section-footer .footer-inner {
  display: flex;
}
section.section-footer .footer-inner .inner-service.w-100 {
  text-align: right;
}
section.section-footer .footer-inner .inner-service ul .heading {
  white-space: nowrap;
  margin-bottom: 20px;
  font-weight: 600;
}
section.section-footer .footer-inner .inner-service ul li a {
  font-size: 16px;
  color: #000;
  white-space: nowrap;
  transition: all 0.4s;
}
section.section-footer .footer-inner .inner-service ul li + li {
  margin-top: 10px;
}
section.section-footer .footer-inner .inner-service .social-media {
  display: flex;
  align-items: center;
}
section.section-footer .footer-inner .inner-service .social-media li a svg {
  height: 18px;
  width: 18px;
  fill: #000;
  transition: all 0.4s;
}
section.section-footer .footer-inner .inner-service .social-media li + li {
  margin-top: 0;
}
section.section-footer .footer-inner .inner-service .social-media li:not(:last-child) {
  margin-right: 10px;
}
section.section-footer .footer-inner .inner-service address {
  margin-bottom: 0;
}
section.section-footer .footer-inner .inner-service address a {
  display: block;
  font-size: 16px;
  color: #000;
  transition: all 0.5s;
}
section.section-footer .footer-inner .inner-service address p {
  margin-top: 15px;
  font-size: 11px;
  color: #000;
}
section.section-footer .footer-inner .inner-service .service-description {
  margin-bottom: 15px;
}
section.section-footer .footer-inner .inner-service .service-description p {
  font-weight: 600;
  color: #000;
}
section.section-footer .footer-inner .inner-service + .inner-service {
  margin-left: 125px;
}
section.section-footer .footer-credits {
  margin-top: 60px;
  padding: 35px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddd;
}
section.section-footer .footer-credits .credits-copyright {
  display: flex;
  align-items: center;
}
section.section-footer .footer-credits .credits-copyright .copyright-logo img {
  height: 25px;
}
section.section-footer .footer-credits .credits-copyright .copyright-logo .logo-text {
  font-weight: 500;
  font-size: 25px;
  font-weight: 600;
  color: #000;
  transition: all 0.4s;
}
section.section-footer .footer-credits .credits-copyright .copyright-logo .logo-text span {
  color: #c31432;
  font-size: 40px;
  line-height: 10px;
}
section.section-footer .footer-credits .credits-copyright .divider {
  border-right: 1px solid #ddd;
  width: 1px;
  height: 30px;
  margin: 0 20px;
}
section.section-footer .footer-credits .credits-copyright p {
  font-size: 14px;
  color: #000;
}
section.section-footer .footer-credits .credits-copyright p a {
  color: inherit;
  transition: all 0.5s;
}
section.section-footer .footer-credits .credits-payment ul {
  display: flex;
  align-items: center;
}
section.section-footer .footer-credits .credits-payment ul li img {
  height: 15px;
}
section.section-footer .footer-credits .credits-payment ul li + li {
  margin-left: 15px;
}

.cookies-alert {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 888;
  width: 330px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  padding: 15px 40px 15px 20px;
  transition: all 0.4s;
}
.cookies-alert .btn-close {
  position: absolute;
  top: 12px;
  right: 6px;
  outline: none;
  box-shadow: none;
  height: auto;
  width: auto;
  transition: opacity 0.4s;
  border: none;
  background: none;
  position: absolute;
  background: none;
  padding: 10px;
  cursor: pointer;
}
.cookies-alert .btn-close span {
  position: absolute;
  display: block;
  left: 0;
  top: 50%;
  width: 12px;
  height: 1px;
  background: #fff;
}
.cookies-alert .btn-close span:nth-child(1) {
  transform: rotate(45deg) translateY(-50%);
}
.cookies-alert .btn-close span:nth-child(2) {
  transform: rotate(-45deg) translateY(-50%);
}
.cookies-alert .btn-close:after {
  content: none;
}
.cookies-alert p {
  font-size: 11px;
  font-weight: 300;
  color: #fff;
}
.cookies-alert p a {
  color: inherit;
  text-decoration: underline;
}
.cookies-alert.hidden {
  visibility: hidden;
  opacity: 0;
}

@media (max-width: 1399.98px) {
  section.section-skins .swiper-outer .outer-cover.left {
    width: 400px;
  }
  section.section-skins .swiper-outer .outer-cover.right {
    width: 400px;
  }
  section.section-cooperate .swiper-outer .outer-cover.left {
    width: 200px;
  }
  section.section-cooperate .swiper-outer .outer-cover.right {
    width: 200px;
  }
  section.section-form .form-inner .inner-text .text-contact {
    flex-direction: column;
    align-items: flex-start;
  }
  section.section-form .form-inner .inner-text .text-contact > * + * {
    margin-left: 0;
    margin-top: 5px;
  }
}
@media (max-width: 1199.98px) {
  nav.section-header .header-flexbox .flexbox-cta ul li .cta-contact {
    display: none;
  }
  .section-heading h2 {
    font-size: 35px !important;
  }
  section.section-welcome .welcome-inner {
    flex-direction: column;
  }
  section.section-welcome .welcome-inner .inner-description {
    width: 100%;
  }
  section.section-welcome .welcome-inner .inner-description h1 {
    font-size: 45px;
  }
  section.section-welcome .welcome-inner .inner-description h1 br {
    content: "";
  }
  section.section-welcome .welcome-inner .inner-description h1 br:after {
    content: " ";
  }
  section.section-welcome .welcome-inner .inner-description .inner-claim {
    margin-top: 20px;
  }
  section.section-welcome .welcome-inner .inner-description .inner-text {
    margin-top: 40px;
    max-width: 850px;
  }
  section.section-welcome .welcome-inner .inner-screen {
    margin-bottom: 100px;
    margin-top: 50px;
    width: 75%;
    padding-top: 31.2%;
  }
  section.section-welcome .welcome-inner .inner-screen .screen-image {
    top: 80%;
  }
  section.section-welcome .welcome-inner .inner-screen .screen-products {
    top: 33%;
  }
  section.section-text-image .text-image-inner {
    align-items: flex-start;
  }
  section.section-about .about-inner .inner-cta {
    flex-direction: column;
  }
  section.section-about .about-inner .inner-cta .cta-flexbox {
    margin-top: 30px;
    margin-left: 0;
  }
  section.section-advantages .advantages-inner-wrapper .advantages-inner .inner-service {
    width: 100%;
  }
  section.section-advantages .advantages-inner-wrapper .advantages-inner .inner-service .service-number {
    min-width: 80px;
    max-width: 80px;
  }
  section.section-advantages .advantages-inner-wrapper .advantages-inner .inner-service + .inner-service {
    margin-top: 30px;
  }
  section.section-advantages .advantages-inner-wrapper .advantages-inner .inner-collapse .collapse-flexbox .inner-service {
    margin-top: 30px;
  }
  section.section-design .design-inner {
    padding-left: 0;
  }
  section.section-design .design-inner .inner-description h2 {
    font-size: 35px;
  }
  section.section-design .design-inner .inner-image img {
    left: -100px;
  }
  section.section-form .form-inner {
    padding: 40px;
    border-radius: 30px;
  }
  section.section-form .form-inner .inner-text h2 {
    font-size: 35px;
  }
  section.section-form .form-inner .inner-text .text-contact {
    margin-top: 30px;
  }
  section.section-pricing table tbody tr td .table-form .input-service .service-input-wrapper svg {
    left: 10px;
  }
  section.section-pricing table tbody tr td .table-form .input-service .service-input-wrapper input {
    text-align: left;
    min-width: 150px;
    font-size: 14px;
    padding-left: 35px;
  }
  section.section-pricing table tbody tr td .table-form .input-service .service-input-wrapper input::placeholder {
    font-size: 14px;
  }
  section.section-pricing .pricing-cta .cta-text {
    margin-top: 100px;
  }
  section.section-pricing .pricing-cta .cta-text h2 {
    font-size: 35px;
  }
  section.section-faq .faq-inner .inner-accordion h2 {
    font-size: 35px;
  }
  section.centrum-pomocy-section-content .content-flexbox .flexbox-service {
    padding: 20px;
  }
  section.testuj-sklep-section-content .section-heading {
    width: 75%;
  }
  section.testuj-sklep-section-content .content-inner {
    width: 75%;
  }
  section.section-footer .footer-inner .inner-service + .inner-service {
    margin-left: 80px;
  }
}
@media (max-width: 991.98px) {
  nav.section-header .header-flexbox .nav-button {
    display: flex;
    margin-right: -10px;
    margin-left: 20px;
  }
  nav.section-header .header-flexbox .flexbox-cta {
    display: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: 50px;
    margin-right: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    flex-direction: column;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a:not(.btn) {
    font-size: 24px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a:not(.btn) img {
    height: 35px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li + li {
    margin-top: 10px;
    margin-left: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.btn-cta {
    margin-top: 30px;
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.social-media {
    margin-left: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  section.section-welcome .welcome-inner .inner-screen {
    margin-bottom: 0;
    width: 100%;
    padding-top: 78%;
  }
  section.section-welcome .welcome-inner .inner-screen .screen-image {
    top: 50%;
  }
  section.section-welcome .welcome-inner .inner-screen .screen-products {
    top: 25%;
  }
  section.section-text-image.pt {
    padding-top: 100px;
  }
  section.section-text-image.pb {
    padding-bottom: 100px;
  }
  section.section-text-image .text-image-inner {
    flex-direction: column;
  }
  section.section-text-image .text-image-inner .inner-image {
    display: none;
  }
  section.section-text-image .text-image-inner .inner-text {
    width: 100%;
  }
  section.section-text-image .text-image-inner .inner-text .text-image {
    margin-top: 30px;
    display: block;
  }
  section.section-advantages .section-heading {
    padding-left: 0;
  }
  section.section-advantages .advantages-inner-wrapper .inner-shape {
    display: none;
  }
  section.section-advantages .advantages-inner-wrapper .advantages-inner {
    margin-top: 50px;
  }
  section.section-design .design-inner .inner-description .btn {
    margin-left: 0;
  }
  section.section-design .design-inner .inner-description .description-text {
    padding-left: 0;
  }
  section.section-form .form-inner {
    padding: 30px;
    flex-direction: column;
  }
  section.section-form .form-inner .inner-text {
    width: 100%;
  }
  section.section-form .form-inner .inner-form {
    margin-top: 20px;
    width: 100%;
  }
  section.section-pricing .pricing-clock-wrapper {
    display: none;
  }
  section.section-pricing .table-wrapper {
    margin-top: 0;
  }
  section.section-pricing .table-responsive table tbody tr td .price {
    font-size: 25px;
    transform: none;
  }
  section.section-pricing .table-responsive table tbody tr td .price span {
    font-size: 11px;
    margin-left: -2px;
  }
  section.section-pricing .table-responsive table tbody tr td .price small {
    margin-left: -2px;
    font-size: 11px;
    font-weight: 700;
  }
  section.section-pricing .table-responsive table tbody tr td .price-promotion {
    font-size: 25px;
    transform: none;
  }
  section.section-pricing .table-responsive table tbody tr td .price-promotion span {
    font-size: 11px;
    margin-left: -2px;
  }
  section.section-pricing .table-responsive table tbody tr td .price-promotion small {
    margin-left: -2px;
    font-size: 11px;
    font-weight: 700;
  }
  section.section-payment-partner .payment-partner-inner {
    flex-direction: column;
  }
  section.section-payment-partner .payment-partner-inner .inner-description {
    text-align: center;
  }
  section.section-payment-partner .payment-partner-inner .inner-image {
    margin-top: 50px;
    padding-left: 0;
    text-align: center;
  }
  section.section-payment-partner .payment-partner-inner .inner-image .image-text {
    padding-left: 0;
  }
  section.section-payment-partner .payment-partner-inner .inner-image img {
    max-width: 100%;
    width: 400px;
  }
  section.section-faq .faq-inner .inner-image {
    display: none;
  }
  section.section-faq .faq-inner .inner-accordion .accordion .accordion-item .accordion-body {
    text-align: left;
  }
  section.section-thank-you .inner-shape-3 {
    display: none;
  }
  section.section-thank-you .thank-you-inner .inner-shape-2 {
    top: 50%;
    left: -300px;
    width: 400px;
  }
  section.static-page-section-content {
    padding: 120px 0 0 0;
  }
  section.static-page-section-content .content-inner h1 {
    font-size: 36px;
  }
  section.centrum-pomocy-section-content {
    padding-top: 120px;
  }
  section.centrum-pomocy-section-content .content-flexbox .flexbox-service {
    padding: 30px;
    width: 50%;
  }
  section.centrum-pomocy-section-content .content-flexbox .flexbox-service:nth-child(2n):after {
    content: none;
  }
  section.centrum-pomocy-section-content .content-flexbox .flexbox-service:nth-child(3):after {
    content: "";
  }
  section.centrum-pomocy-section-content .content-flexbox .flexbox-service:nth-child(3):before {
    content: "";
  }
  section.dodatkowe-skorki-section-content {
    padding-top: 120px;
  }
  section.testuj-sklep-section-content {
    padding-top: 175px;
  }
  section.testuj-sklep-section-content .section-heading {
    width: 70%;
  }
  section.testuj-sklep-section-content .content-inner {
    margin-top: 50px;
    width: 100%;
  }
  section.section-footer .footer-inner .inner-service + .inner-service {
    margin-left: 47px;
  }
}
@media (max-width: 767.98px) {
  .container.full-width {
    max-width: 100%;
    padding: 0 30px;
  }
  section.section-advantages .section-heading h2 {
    text-align: center;
  }
  section.section-advantages .advantages-inner-wrapper .inner-shape {
    left: 50%;
    width: 300px;
    transform: translate(-50%, -60%);
  }
  section.section-skins .swiper-outer .outer-cover.left {
    width: 150px;
  }
  section.section-skins .swiper-outer .outer-cover.right {
    width: 150px;
  }
  section.section-cooperate .swiper-outer .outer-cover.left {
    width: 180px;
  }
  section.section-cooperate .swiper-outer .outer-cover.right {
    width: 180px;
  }
  section.section-cooperate .swiper-cooperate .cooperate-item {
    width: 120px;
  }
  section.section-cooperate .swiper-cooperate .cooperate-item.w-2 {
    width: 104px;
  }
  section.section-cooperate .swiper-cooperate .cooperate-item.w-3 {
    width: 112px;
  }
  section.section-pricing .table-responsive {
    overflow-x: auto;
  }
  section.section-pricing .table-responsive table tbody tr td .heading {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 20px;
  }
  section.section-pricing .table-responsive table tbody tr td .heading strong {
    color: #c31432;
  }
  section.section-pricing .table-responsive table tbody tr td .price-heading {
    margin-top: 5px;
  }
  section.section-pricing .table-responsive table tbody tr td .price {
    white-space: nowrap;
  }
  section.section-pricing .table-responsive table tbody tr td .value {
    line-height: 20px;
    letter-spacing: -0.2px;
    font-size: 14px;
  }
  section.section-pricing .table-responsive table tbody tr:first-child td:first-child .heading {
    transform: translateY(-8px);
  }
  section.section-shop-test {
    padding: 100px 0;
  }
  section.section-shop-test .shop-test-image {
    width: 100%;
    opacity: 0.3;
  }
  section.section-shop-test .shop-test-inner {
    width: 100%;
    text-align: center;
  }
  section.section-marketing .marketing-inner .inner-description h2 {
    text-align: center;
  }
  section.section-marketing .marketing-inner .inner-description .description-text {
    flex-wrap: wrap;
  }
  section.section-marketing .marketing-inner .inner-description .description-text .text-shape {
    right: auto;
    left: 50%;
    transform: translate(-50%, -100%);
  }
  section.section-marketing .marketing-inner .inner-description .description-text .text-service {
    width: 100%;
  }
  section.section-marketing .marketing-inner .inner-description .description-text .text-service + .text-service {
    margin-top: 25px;
  }
  section.centrum-pomocy-section-content .content-flexbox .flexbox-service {
    width: 100%;
    padding: 0;
  }
  section.centrum-pomocy-section-content .content-flexbox .flexbox-service:after {
    content: none !important;
  }
  section.centrum-pomocy-section-content .content-flexbox .flexbox-service:before {
    content: none !important;
  }
  section.centrum-pomocy-section-content .content-flexbox .flexbox-service + .flexbox-service {
    margin-top: 40px;
  }
  section.dodatkowe-skorki-section-content .content-flexbox .flexbox-service {
    width: 100%;
  }
  section.testuj-sklep-section-content .section-heading {
    width: 85%;
  }
  section.testuj-sklep-section-content .content-inner {
    flex-direction: column;
  }
  section.testuj-sklep-section-content .content-inner .inner-advantages {
    width: 100%;
    flex-direction: row;
  }
  section.testuj-sklep-section-content .content-inner .inner-advantages .advantage-service {
    width: 32%;
  }
  section.testuj-sklep-section-content .content-inner .inner-form {
    margin-top: 30px;
    width: 100%;
  }
  section.section-footer {
    padding-top: 70px;
  }
  section.section-footer .footer-inner {
    flex-wrap: wrap;
  }
  section.section-footer .footer-inner .breakpoint {
    order: 3;
    width: 100%;
  }
  section.section-footer .footer-inner .inner-service {
    margin-top: 30px;
  }
  section.section-footer .footer-inner .inner-service.w-100 {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .container.full-width {
    max-width: 100%;
    padding: 0 15px;
  }
  .pricing-clock-widget {
    bottom: 6px;
    right: 6px;
    width: calc(100% - 12px);
    padding: 10px;
    border-radius: 10px;
    justify-content: space-between;
  }
  .pricing-clock-widget .pricing-clock-heading {
    font-size: 13px;
    margin-right: 15px;
  }
  .pricing-clock-widget .pricing-clock .clock-service + .clock-service {
    margin-left: 8px;
  }
  nav.section-header .header-flexbox {
    padding-top: 20px;
  }
  nav.section-header .header-flexbox .flexbox-logo .logo-text {
    font-size: 18px;
    font-weight: 700;
  }
  nav.section-header .header-flexbox .flexbox-logo .logo-text span {
    color: #c31432;
    font-size: 40px;
    line-height: 10px;
  }
  section.section-about {
    padding: 80px 0;
  }
  section.section-about .about-inner .inner-cta .cta-flexbox {
    flex-direction: column;
  }
  section.section-about .about-inner .inner-cta .cta-flexbox .btn + .btn {
    margin-top: 15px;
    margin-left: 0;
  }
  section.section-advantages {
    padding: 80px 0;
  }
  section.section-advantages .advantages-inner-wrapper .advantages-inner {
    margin-top: 40px;
  }
  section.section-advantages .advantages-cta .cta-form .input-service {
    width: 100%;
    margin-top: 10px;
  }
  section.section-advantages .advantages-cta .cta-form .input-service label {
    font-size: 12px;
    color: #000;
  }
  section.section-advantages .advantages-cta .cta-form .input-service input {
    display: block;
    width: 100%;
    height: 45px;
    outline: none;
    border: 1px dotted #000;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    transition: all 0.4s;
  }
  section.section-advantages .advantages-cta .cta-form .input-service input:focus {
    border: 1px solid #c31432;
  }
  section.section-advantages .advantages-cta .cta-form .btn {
    margin-top: 30px;
    width: 100%;
  }
  section.section-advantages .advantages-cta .cta-form.active {
    margin-top: -45px;
  }
  section.section-design .design-inner {
    flex-direction: column;
  }
  section.section-design .design-inner .inner-description {
    padding: 80px 0;
    text-align: center;
  }
  section.section-design .design-inner .inner-image {
    padding-top: 55%;
  }
  section.section-design .design-inner .inner-image img {
    top: -50px;
    left: auto;
    right: 0;
    transform: translateX(40%);
    height: auto;
    width: 150%;
  }
  section.section-skins {
    padding: 80px 0 40px 0;
  }
  section.section-skins .swiper-outer .outer-cover.left {
    width: 50px;
  }
  section.section-skins .swiper-outer .outer-cover.right {
    width: 50px;
  }
  section.section-skins .swiper-skins .skin-item {
    width: 300px;
  }
  section.section-cooperate {
    padding: 80px 0;
  }
  section.section-cooperate .swiper-outer {
    margin-top: 30px;
  }
  section.section-cooperate .swiper-outer .outer-cover.left {
    width: 50px;
  }
  section.section-cooperate .swiper-outer .outer-cover.right {
    width: 50px;
  }
  section.section-cooperate .swiper-cooperate .cooperate-item {
    width: 90px;
  }
  section.section-cooperate .swiper-cooperate .cooperate-item.w-2 {
    width: 78px;
  }
  section.section-cooperate .swiper-cooperate .cooperate-item.w-3 {
    width: 84px;
  }
  section.section-pricing {
    padding-top: 120px;
    padding-bottom: 80px;
  }
  section.section-pricing .pricing-clock-wrapper .pricing-clock .clock-service .value {
    height: 60px;
    min-width: 60px;
    max-width: 60px;
  }
  section.section-shop-test {
    padding: 80px 0;
  }
  section.section-payment-partner {
    padding: 80px 0;
  }
  section.section-faq {
    padding-top: 70px;
  }
  section.section-marketing {
    padding: 80px 0;
  }
  section.section-thank-you .thank-you-inner .inner-shape-1 {
    width: 170px;
  }
  section.section-thank-you .thank-you-inner .inner-shape-2 {
    display: none;
  }
  section.section-thank-you .thank-you-inner .inner-logo {
    margin-top: 40px;
  }
  section.section-thank-you .thank-you-inner .inner-description {
    margin-top: 0px;
  }
  section.section-thank-you .thank-you-inner .inner-cta {
    margin-top: 50px;
  }
  section.formularz-section-content .content-close {
    top: 12px;
    right: 12px;
    width: 38px;
    height: 38px;
  }
  section.formularz-section-content .content-close img {
    height: 12px;
    width: 12px;
  }
  section.testuj-sklep-section-content {
    padding-top: 120px;
  }
  section.testuj-sklep-section-content .content-background {
    display: none;
  }
  section.testuj-sklep-section-content .section-heading {
    width: 100%;
    text-align: center;
  }
  section.section-footer {
    padding-top: 40px;
  }
  section.section-footer .footer-inner {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  section.section-footer .footer-inner .inner-service + .inner-service {
    margin-left: 0;
  }
  section.section-footer .footer-credits {
    padding: 25px 0;
  }
  section.section-footer .footer-credits .credits-copyright {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }
  section.section-footer .footer-credits .credits-copyright .divider {
    display: none;
  }
}
@media (max-width: 500px) {
  nav.section-header .header-flexbox .flexbox-cta {
    display: none;
  }
  .section-heading h2 {
    font-size: 30px !important;
  }
  section.section-welcome .welcome-inner {
    padding-top: 120px;
  }
  section.section-welcome .welcome-inner .inner-description h1 {
    font-size: 36px;
  }
  section.section-welcome .welcome-inner .inner-description .inner-claim {
    display: none;
  }
  section.section-welcome .welcome-inner .inner-description .inner-text {
    margin-top: 0;
  }
  section.section-welcome .welcome-inner .inner-description .inner-text p {
    margin-top: 20px;
  }
  section.section-welcome .welcome-inner .inner-description .inner-form {
    border-radius: 0;
    background: none;
  }
  section.section-welcome .welcome-inner .inner-description .inner-form .input-service {
    flex-direction: column;
  }
  section.section-welcome .welcome-inner .inner-description .inner-form .input-service input {
    padding-right: 20px;
  }
  section.section-welcome .welcome-inner .inner-description .inner-form .btn {
    margin-top: 10px;
    position: relative;
    top: auto;
    transform: none;
    right: auto;
    width: 100%;
  }
  section.section-welcome .welcome-inner .inner-screen {
    margin-top: 30px;
  }
  section.section-text-image .text-image-inner .inner-text h2 {
    font-size: 30px;
  }
  section.section-advantages .advantages-inner-wrapper .advantages-inner {
    padding: 20px 20px 40px 20px;
  }
  section.section-advantages .advantages-inner-wrapper .advantages-inner .inner-service + .inner-service {
    margin-top: 0;
  }
  section.section-advantages .advantages-inner-wrapper .advantages-inner .inner-collapse .collapse-flexbox .inner-service {
    margin-top: 30px;
  }
  section.section-advantages .advantages-inner-wrapper .advantages-inner .inner-collapse .collapse-flexbox .inner-service:first-child {
    margin-top: 0;
  }
  section.section-design .design-inner .inner-description {
    padding-top: 0;
  }
  section.section-design .design-inner .inner-description h2 {
    font-size: 30px;
  }
  section.section-skins {
    margin-top: 50px;
  }
  section.section-skins .skins-inner {
    padding: 50px 0 30px 0;
  }
  section.section-cooperate {
    padding: 30px 0;
  }
  section.section-pricing {
    padding-top: 70px;
  }
  section.section-pricing .pricing-cta .cta-text {
    margin-top: 70px;
  }
  section.section-pricing .pricing-cta .cta-text .btn {
    margin-top: 40px;
  }
  section.section-form .form-inner {
    padding: 20px;
  }
  section.section-form .form-inner .inner-text h2 {
    font-size: 30px;
  }
  section.section-form .form-inner .inner-text .text-contact {
    margin-top: 20px;
  }
  section.section-form .form-inner .inner-text .text-contact .contact-service a {
    font-size: 22px;
  }
  section.section-form .form-inner .inner-form {
    border-radius: 0;
    background: none;
  }
  section.section-form .form-inner .inner-form .input-service {
    flex-direction: column;
  }
  section.section-form .form-inner .inner-form .input-service input {
    padding-right: 20px;
  }
  section.section-form .form-inner .inner-form .btn {
    margin-top: 10px;
    position: relative;
    top: auto;
    transform: none;
    right: auto;
    width: 100%;
  }
  section.section-pricing table tbody tr td .table-form .input-service .service-input-wrapper svg {
    left: 10px;
  }
  section.section-pricing table tbody tr td .table-form .input-service .service-input-wrapper input {
    text-align: left;
    min-width: 150px;
    font-size: 14px;
    padding-left: 35px;
  }
  section.section-pricing table tbody tr td .table-form .input-service .service-input-wrapper input::placeholder {
    font-size: 14px;
  }
  section.section-pricing .pricing-cta .cta-text h2 {
    font-size: 30px;
  }
  section.section-faq .faq-inner .inner-accordion h2 {
    font-size: 30px;
  }
  section.testuj-sklep-section-content .content-inner .inner-advantages .advantage-service {
    padding: 10px;
  }
  section.testuj-sklep-section-content .content-inner .inner-advantages .advantage-service .name {
    font-size: 17px;
    line-height: 20px;
  }
  section.testuj-sklep-section-content .content-inner .inner-advantages .advantage-service .text {
    font-size: 14px;
    line-height: 13px;
  }
}