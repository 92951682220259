
// Extralarge devices (desktops, less than 1400px)
@media (max-width: 1399.98px) {
	section.section-skins {
		.swiper-outer {
			.outer-cover {
				&.left {
					width: 400px;
				}

				&.right {
					width: 400px;
				}
			}
		}
	}

	section.section-cooperate {
		.swiper-outer {
			.outer-cover {
				&.left {
					width: 200px;
				}

				&.right {
					width: 200px;
				}
			}
		}
	}

	section.section-form {
		.form-inner {
			.inner-text {
				.text-contact {
					flex-direction: column;
					align-items: flex-start;

					> * {
						& + * {
							margin-left: 0;
							margin-top: 5px;
						}
					}
				}
			}
		}
	}
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
	nav.section-header {
		.header-flexbox {
			.flexbox-cta {
				ul {
					li {
						.cta-contact {
							display: none;
						}
					}
				}
			}
		}
	}

	.section-heading {
		h2 {
			font-size: 35px !important;
		}
	}

	section.section-welcome {
		.welcome-inner {
			flex-direction: column;

			.inner-description {
				width: 100%;

				h1 {
					font-size: 45px;

					br {
						content: '';

						&:after {
							content: ' ';
						}
					}
				}

				.inner-claim {
					margin-top: 20px;
				}

				.inner-text {
					margin-top: 40px;
					max-width: 850px;
				}
			}

			.inner-screen {
				margin-bottom: 100px;
				margin-top: 50px;
				width: 75%;
				padding-top: 39% * 0.8;

				.screen-image {
					top: 80%;
				}

				.screen-products {
					top: 33%;
				}
			}
		}
	}

	section.section-text-image {
		.text-image-inner {
			align-items: flex-start;
		}
	}

	section.section-about {
		.about-inner {
			.inner-cta {
				flex-direction: column;

				.cta-flexbox {
					margin-top: 30px;
					margin-left: 0;
				}
			}
		}
	}

	section.section-advantages {
		.advantages-inner-wrapper {
			.advantages-inner {
				.inner-service {
					width: 100%;

					.service-number {
						min-width: 80px;
						max-width: 80px;
					}

					& + .inner-service {
						margin-top: 30px;
					}
				}

				.inner-collapse {
					.collapse-flexbox {
						.inner-service {
							margin-top: 30px;
						}
					}
				}
			}
		}
	}

	section.section-design {
		.design-inner {
			padding-left: 0;

			.inner-description {
				h2 {
					font-size: 35px;
				}
			}

			.inner-image {
				img {
					left: -100px;
				}
			}
		}
	}

	section.section-form {
		.form-inner {
			padding: 40px;
			border-radius: 30px;

			.inner-text {

				h2 {
					font-size: 35px;
				}

				.text-contact {
					margin-top: 30px;
				}
			}
		}
	}

	section.section-pricing {
		table {
			tbody {
				tr {
					td {
						.table-form {
							.input-service {
								.service-input-wrapper {
									svg {
										left: 10px;
									}

									input {
										text-align: left;
										min-width: 150px;
										font-size: 14px;
										padding-left: 35px;

										&::placeholder {
											font-size: 14px;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.pricing-cta {
			.cta-text {
				margin-top: 100px;

				h2 {
					font-size: 35px;
				}
			}
		}
	}

	section.section-faq {
		.faq-inner {
			.inner-accordion {
				h2 {
					font-size: 35px;
				}
			}
		}
	}

	section.centrum-pomocy-section-content {
		.content-flexbox {
			.flexbox-service {
				padding: 20px;
			}
		}
	}

	section.testuj-sklep-section-content {
		.section-heading {
			width: 75%;
		}

		.content-inner {
			width: 75%;
		}
	}

	section.section-footer {
		.footer-inner {
			.inner-service {
				& + .inner-service {
					margin-left: 80px;
				}
			}
		}
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
	nav.section-header {
		.header-flexbox {
			.nav-button {
				display: flex;
				margin-right: -10px;
				margin-left: 20px;
			}

			.flexbox-cta {
				display: none;
			}

			.flexbox-nav-wrapper {
				position: fixed;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				overflow-y: scroll;
				background: #fff;
				z-index: 555;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s;

				.nav-button-close {
					display: block;
				}

				.flexbox-nav-inner {
					display: flex;
					flex-direction: column;
					justify-content: center;
					min-height: 100%;
					padding: 50px;
					margin-right: 0;

					.flexbox-nav {
						flex-direction: column;

						li {
							a {
								&:not(.btn) {
									font-size: 24px;

									img {
										height: 35px;
									}
								}
							}

							& + li {
								margin-top: 10px;
								margin-left: 0;
							}

							&.btn-cta {
								margin-top: 30px;
								display: block;
							}

							&.social-media {
								margin-left: 0;
							}
						}
					}
				}

				&.active {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	section.section-welcome {
		.welcome-inner {
			.inner-screen {
				margin-bottom: 0;
				width: 100%;
				padding-top: 39% * 2;

				.screen-image {
					top: 50%;
				}

				.screen-products {
					top: 25%;
				}
			}
		}
	}

	section.section-text-image {
		&.pt {
			padding-top: 100px;
		}

		&.pb {
			padding-bottom: 100px;
		}

		.text-image-inner {
			flex-direction: column;

			.inner-image {
				display: none;
			}

			.inner-text {
				width: 100%;

				.text-image {
					margin-top: 30px;
					display: block;
				}
			}
		}
	}

	section.section-advantages {
		.section-heading {
			padding-left: 0;
		}

		.advantages-inner-wrapper {
			.inner-shape {
				display: none;
			}

			.advantages-inner {
				margin-top: 50px;
			}
		}
	}

	section.section-design {
		.design-inner {
			.inner-description {
				.btn {
					margin-left: 0;
				}

				.description-text {
					padding-left: 0;
				}
			}
		}

	}

	section.section-form {
		.form-inner {
			padding: 30px;
			flex-direction: column;

			.inner-text {
				width: 100%;
			}

			.inner-form {
				margin-top: 20px;
				width: 100%;
			}
		}
	}

	section.section-pricing {
		.pricing-clock-wrapper {
			display: none;
		}

		.table-wrapper {
			margin-top: 0;
		}

		.table-responsive {
			table {
				tbody {
					tr {
						td {
							.price {
								font-size: 25px;
								transform: none;

								span {
									font-size: 11px;
									margin-left: -2px;
								}

								small {
									margin-left: -2px;
									font-size: 11px;
									font-weight: 700;
								}
							}

							.price-promotion {
								font-size: 25px;
								transform: none;

								span {
									font-size: 11px;
									margin-left: -2px;
								}

								small {
									margin-left: -2px;
									font-size: 11px;
									font-weight: 700;
								}
							}
						}
					}
				}
			}
		}
	}

	section.section-payment-partner {
		.payment-partner-inner {
			flex-direction: column;

			.inner-description {
				text-align: center;
			}

			.inner-image {
				margin-top: 50px;
				padding-left: 0;
				text-align: center;

				.image-text {
					padding-left: 0;
				}

				img {
					max-width: 100%;
					width: 400px;
				}
			}
		}
	}

	section.section-faq {
		.faq-inner {
			.inner-image {
				display: none;
			}

			.inner-accordion {
				.accordion {
					.accordion-item {
						.accordion-body {
							text-align: left;
						}
					}
				}
			}
		}
	}

	section.section-thank-you {
		.inner-shape-3 {
			display: none;
		}

		.thank-you-inner {
			.inner-shape-2 {
				top: 50%;
				left: -300px;
				width: 400px;
			}
		}
	}

	section.static-page-section-content {
		padding: 120px 0 0 0;

		.content-inner {
			h1 {
				font-size: 36px;
			}
		}
	}

	section.centrum-pomocy-section-content {
		padding-top: 120px;

		.content-flexbox {
			.flexbox-service {
				padding: 30px;
				width: calc(100% / 2);

				&:nth-child(2n) {
					&:after {
						content: none;
					}
				}

				&:nth-child(3) {
					&:after {
						content: '';
					}

					&:before {
						content: '';
					}
				}
			}
		}
	}

	section.dodatkowe-skorki-section-content {
		padding-top: 120px;
	}

	section.testuj-sklep-section-content {
		padding-top: 175px;

		.section-heading {
			width: 70%;
		}

		.content-inner {
			margin-top: 50px;
			width: 100%;
		}
	}

	section.section-footer {
		.footer-inner {
			.inner-service {
				& + .inner-service {
					margin-left: 47px;
				}
			}
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	.container.full-width {
		max-width: 100%;
		padding: 0 30px;
	}

	section.section-advantages {
		.section-heading {
			h2 {
				text-align: center;
			}
		}

		.advantages-inner-wrapper {
			.inner-shape {
				left: 50%;
				width: 300px;
				transform: translate(-50%, -60%);
			}
		}
	}

	section.section-skins {
		.swiper-outer {
			.outer-cover {
				&.left {
					width: 150px;
				}

				&.right {
					width: 150px;
				}
			}
		}
	}

	section.section-cooperate {
		.swiper-outer {
			.outer-cover {
				&.left {
					width: 180px;
				}

				&.right {
					width: 180px;
				}
			}
		}

		.swiper-cooperate {
			.cooperate-item {
				width: 150px * 0.8;

				&.w-2 {
					width: 130px * 0.8;
				}

				&.w-3 {
					width: 140px * 0.8;
				}
			}
		}
	}

	section.section-pricing {
		.table-responsive {
			overflow-x: auto;

			table {
				tbody {
					tr {
						td {
							.heading {
								margin-top: 5px;
								display: flex;
								flex-direction: column;
								align-items: center;
								justify-content: center;
								line-height: 20px;

								strong {
									color: $color1;
								}
							}

							.price-heading {
								margin-top: 5px;
							}

							.price {
								white-space: nowrap;
							}

							.value {
								line-height: 20px;
								letter-spacing: -0.2px;
								font-size: 14px;
							}
						}

						&:first-child {
							td:first-child {
								.heading {
									transform: translateY(-8px);
								}
							} 
						}
					}
				}
			}
		}
	}

	section.section-shop-test {
		padding: 100px 0;

		.shop-test-image {
			width: 100%;
			opacity: 0.3;
		}

		.shop-test-inner {
			width: 100%;
			text-align: center;
		}
	}

	section.section-marketing {
		.marketing-inner {
			.inner-description {
				h2 {
					text-align: center;
				}

				.description-text {
					flex-wrap: wrap;

					.text-shape {
						right: auto;
						left: 50%;
						transform: translate(-50%, -100%);
					}

					.text-service {
						width: 100%;

						& + .text-service {
							margin-top: 25px;
						}
					}
				}
			}
		}
	}

	section.centrum-pomocy-section-content {
		.content-flexbox {
			.flexbox-service {
				width: 100%;
				padding: 0;

				&:after {
					content: none !important;
				}

				&:before {
					content: none !important;
				}

				& + .flexbox-service {
					margin-top: 40px;
				}
			}
		}
	}

	section.dodatkowe-skorki-section-content {
		.content-flexbox {
			.flexbox-service {
				width: 100%;
			}
		}
	}

	section.testuj-sklep-section-content {
		.section-heading {
			width: 85%;
		}

		.content-inner {
			flex-direction: column;

			.inner-advantages {
				width: 100%;
				flex-direction: row;

				.advantage-service {
					width: 32%;
				}
			}

			.inner-form {
				margin-top: 30px;
				width: 100%;
			}
		}
	}

	section.section-footer {
		padding-top: 70px;

		.footer-inner {
			flex-wrap: wrap;

			.breakpoint {
				order: 3;
				width: 100%;
			}

			.inner-service {
				margin-top: 30px;

				&.w-100 {
					display: none;
				}
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
	.container.full-width {
		max-width: 100%;
		padding: 0 15px;
	}

	.pricing-clock-widget {
		bottom: 6px;
		right: 6px;
		width: calc(100% - 12px);
		padding: 10px;
		border-radius: 10px;
		justify-content: space-between;

		.pricing-clock-heading {
			font-size: 13px;
			margin-right: 15px;
		}

		.pricing-clock {
			.clock-service {
				& + .clock-service {
					margin-left: 8px;
				}
			}
		}
	}

	nav.section-header {
		.header-flexbox {
			padding-top: 20px;

			.flexbox-logo {
				.logo-text {
					font-size: 18px;
					font-weight: 700;

					span {
						color: #c31432;
						font-size: 40px;
						line-height: 10px;
					}
				}
			}
		}
	}

	section.section-about {
		padding: 80px 0;

		.about-inner {
			.inner-cta {
				.cta-flexbox {
					flex-direction: column;

					.btn {
						& + .btn {
							margin-top: 15px;
							margin-left: 0;
						}
					}
				}
			}
		}
	}

	section.section-advantages {
		padding: 80px 0;

		.advantages-inner-wrapper {
			.advantages-inner {
				margin-top: 40px;
			}
		}

		.advantages-cta {
			.cta-form {
				.input-service {
					width: 100%;
					margin-top: 10px;

					label {
						font-size: 12px;
						color: #000;
					}

					input {
						display: block;
						width: 100%;
						height: 45px;
						outline: none;
						border: 1px dotted #000;
						border-radius: 10px;
						padding: 10px;
						text-align: center;
						transition: all 0.4s;

						&:focus {
							border: 1px solid $color1;
						}
					}
				}

				.btn {
					margin-top: 30px;
					width: 100%;
				}

				&.active {
					margin-top: -45px;
				}
			}
		}
	}

	section.section-design {
		.design-inner {
			flex-direction: column;

			.inner-description {
				padding: 80px 0;
				text-align: center;
			}

			.inner-image {
				padding-top: 55%;

				img {
					top: -50px;
					left: auto;
					right: 0;
					transform: translateX(40%);
					height: auto;
					width: 150%;
				}
			}
		}
	}

	section.section-skins {
		padding: 80px 0 40px 0;

		.swiper-outer {
			.outer-cover {
				&.left {
					width: 50px;
				}

				&.right {
					width: 50px;
				}
			}
		}

		.swiper-skins {
			.skin-item {
				width: 300px;
			}
		}
	}

	section.section-cooperate {
		padding: 80px 0;

		.swiper-outer {
			margin-top: 30px;

			.outer-cover {
				&.left {
					width: 50px;
				}

				&.right {
					width: 50px;
				}
			}
		}

		.swiper-cooperate {
			.cooperate-item {
				width: 150px * 0.6;

				&.w-2 {
					width: 130px * 0.6;
				}

				&.w-3 {
					width: 140px * 0.6;
				}
			}
		}
	}

	section.section-pricing {
		padding-top: 120px;
		padding-bottom: 80px;

		.pricing-clock-wrapper {
			.pricing-clock {
				.clock-service {
					.value {
						height: 60px;
						min-width: 60px;
						max-width: 60px;
					}
				}
			}
		}
	}

	section.section-shop-test {
		padding: 80px 0;
	}

	section.section-payment-partner {
		padding: 80px 0;
	}

	section.section-faq {
		padding-top: 70px;
	}

	section.section-marketing {
		padding: 80px 0;
	}

	section.section-thank-you {
		.thank-you-inner {
			.inner-shape-1 {
				width: 170px;
			}

			.inner-shape-2 {
				display: none;
			}

			.inner-logo {
				margin-top: 40px;
			}

			.inner-description {
				margin-top: 0px;
			}

			.inner-cta {
				margin-top: 50px;
			}
		}
	}

	section.formularz-section-content {
		.content-close {
			top: 12px;
			right: 12px;
			width: 38px;
			height: 38px;

			img {
				height: 12px;
				width: 12px;
			}
		}
	}

	section.testuj-sklep-section-content {
		padding-top: 120px;

		.content-background {
			display: none;
		}

		.section-heading {
			width: 100%;
			text-align: center;
		}
	}

	section.section-footer {
		padding-top: 40px;

		.footer-inner {
			flex-direction: column;
			align-items: center;
			text-align: center;

			.inner-service {
				& + .inner-service {
					margin-left: 0;
				}
			}
		}

		.footer-credits {
			padding: 25px 0;

			.credits-copyright {
				flex-direction: column;
				align-items: center;
				text-align: center;
				width: 100%;

				.divider {
					display: none;
				}
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 500px) {
	nav.section-header {
		.header-flexbox {
			.flexbox-cta {
				display: none;
			}
		}
	}

	.section-heading {
		h2 {
			font-size: 30px !important;
		}
	}

	section.section-welcome {
		.welcome-inner {
			padding-top: 120px;

			.inner-description {
				h1 {
					font-size: 36px;
				}

				.inner-claim {
					display: none;
				}

				.inner-text {
					margin-top: 0;

					p {
						margin-top: 20px;
					}
				}

				.inner-form {
					border-radius: 0;
					background: none;

					.input-service {
						flex-direction: column;

						input {
							padding-right: 20px;
						}
					}

					.btn {
						margin-top: 10px;
						position: relative;
						top: auto;
						transform: none;
						right: auto;
						width: 100%;
					}
				}
			}

			.inner-screen {
				margin-top: 30px;
			}
		}
	}

	section.section-text-image {
		.text-image-inner {
			.inner-text {
				h2 {
					font-size: 30px;
				}
			}
		}
	}

	section.section-advantages {
		.advantages-inner-wrapper {
			.advantages-inner {
				padding: 20px 20px 40px 20px;

				.inner-service {
					& + .inner-service {
						margin-top: 0;
					}
				}

				.inner-collapse {
					.collapse-flexbox {
						.inner-service {
							margin-top: 30px;

							&:first-child {
								margin-top: 0;
							}
						}
					}
				}
			}
		}
	}

	section.section-design {
		.design-inner {
			.inner-description {
				padding-top: 0;

				h2 {
					font-size: 30px;
				}
			}
		}

	}

	section.section-skins {
		margin-top: 50px;

		.skins-inner {
			padding: 50px 0 30px 0;
		}
	}

	section.section-cooperate {
		padding: 30px 0;
	}

	section.section-pricing {
		padding-top: 70px;

		.pricing-cta {
			.cta-text {
				margin-top: 70px;

				.btn {
					margin-top: 40px;
				}
			}
		}
	}

	section.section-form {
		.form-inner {
			padding: 20px;

			.inner-text {
				h2 {
					font-size: 30px;
				}

				.text-contact {
					margin-top: 20px;

					.contact-service {
						a {
							font-size: 22px;
						}
					}
				}
			}

			.inner-form {
				border-radius: 0;
				background: none;

				.input-service {
					flex-direction: column;

					input {
						padding-right: 20px;
					}
				}

				.btn {
					margin-top: 10px;
					position: relative;
					top: auto;
					transform: none;
					right: auto;
					width: 100%;
				}
			}
		}
	}

	section.section-pricing {
		table {
			tbody {
				tr {
					td {
						.table-form {
							.input-service {
								.service-input-wrapper {
									svg {
										left: 10px;
									}

									input {
										text-align: left;
										min-width: 150px;
										font-size: 14px;
										padding-left: 35px;

										&::placeholder {
											font-size: 14px;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.pricing-cta {
			.cta-text {
				h2 {
					font-size: 30px;
				}
			}
		}
	}

	section.section-faq {
		.faq-inner {
			.inner-accordion {
				h2 {
					font-size: 30px;
				}
			}
		}
	}

	section.testuj-sklep-section-content {
		.content-inner {
			.inner-advantages {
				.advantage-service {
					padding: 10px;

					.name {
						font-size: 17px;
						line-height: 20px;
					}

					.text {
						font-size: 14px;
						line-height: 13px;
					}
				}
			}
		}
	}
}