@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

$color1: #c31432;

body {
	min-height: 100vh;
	height: 100%;
	font-family: "Plus Jakarta Sans", serif;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&.no-js {
		.intro {
			opacity: 1;
			transform: none;
		}
	}

	&.no-scroll {
		overflow: hidden;
		max-height: 100vh;
	}

	.btn {
		font-weight: 600;
		padding: 9px 25px;
		border-radius: 50px;
		font-size: 14px;
		letter-spacing: 0.5px;
		transition: all 0.4s;

		span {
			font-weight: 300;
		}

		&.btn-default {
			background: $color1;
			color: #fff;

			&:hover {
				background: darken($color1, 10%);
			}
		}

		&.btn-secondary {
			background: #fff;
			color: #000;
			border: 1px solid $color1;

			&:hover {
				background: $color1;
				color: #fff;
			}
		}

		&.btn-white {
			background: #fff;
			color: $color1;

			&:hover {
				color: #fff;
				background: darken($color1, 20%);
			}
		}

		&.btn-big {
			font-size: 14px;
			padding: 12px 35px 10px 35px;
		}

		&.btn-small {
			padding: 7px 15px 6px 15px;
			font-size: 10px;
		}

		&.btn-fixed {
			position: fixed;
			z-index: 999;
			right: 20px;
			bottom: 20px;
		}
	}

	ul, ol, p, h1, h2, h3, h4, h5, h6 {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none;
	}
}

.body-wrapper {
	overflow: hidden;	
}

.alert-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.2);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.4s;

	.alert-service {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 100vh;
		width: 100%;
		transform: translateX(8px);
		padding: 50px 0;

		.service-inner {
			padding: 50px;
			background: #fff;
			width: 100%;
			box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
			text-align: center;
			border-top: 5px solid #fff;
			position: relative;
			transform: scale(0.8);
			transition: all 0.5s;

			.inner-dismiss {
				top: 10px;
				right: 10px;
				position: absolute;
				border: 0;
				background: none;
				padding: 0;
				cursor: pointer;
				display: flex;
				padding: 5px;
				outline: none;

				img {
					height: 15px;
				}
			}

			&.success {
				border-top: 5px solid #78b042;
			}

			&.warning {
				border-top: 5px solid #d51a27;
			}

			&.info {
				border-top: 5px solid #1a88d5;
			}
		}
	}

	&.active {
		opacity: 1;
		visibility: visible;

		.alert-service {
			.service-inner {
				transform: none;
			}
		}
	}
}

.modal-service-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.5);
	z-index: 999;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.modal-service {
		padding: 50px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100%;

		.service-inner {
			background: #fff;
			width: 990px;
			max-width: 100%;
			padding: 25px;
			position: relative;

			.service-button {
				position: absolute;
				top: 16px;
				right: 12px;
				border: none;
				background: none;
				padding: 15px;
				display: flex;
				justify-content: center;
				align-items: center;

				svg {
					fill: #000;
					height: 20px;
					min-width: 20px;
					max-width: 20px;
					transform: rotate(45deg);
					transition: all 0.3s;
				}

				&:hover {
					svg {
						fill: $color1;
					}
				}
			}

			.service-content {
				.heading {
					font-size: 25px;
					color: #000;
					font-weight: 500;
					padding-right: 50px;
				}

				.description {
					margin-top: 35px;

					p, li {
						color: #000;
						font-size: 17px;
						line-height: 35px;
						font-weight: 400;
					}

					ol, ul {
						padding-left: 17px;
					}

					h1 {
						color: $color1;
						font-size: 30px;
						letter-spacing: 1px;
						text-transform: uppercase;
					}

					h2 {
						color: $color1;
						font-size: 24px;
						letter-spacing: 1px;
						text-transform: uppercase;
					}

					h3 {
						color: $color1;
						font-size: 20px;
						letter-spacing: 1px;
						text-transform: uppercase;
					}
				}
			}
		}
	}

	&.full-width {
		.modal-service {
			padding: 0;

			.service-inner {
				width: 100%;
				min-height: 100vh;
			}
		}
	}
}

.fancybox-image {
	border-radius: 30px;
}

.pricing-clock-widget {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 999;
	background: #222;
	border-radius: 15px;
	padding: 20px;
	display: flex;
	align-items: center;

	.pricing-clock-heading {
		font-size: 14px;
		color: #fff;
		font-weight: 600;
		margin-right: 25px;
	}

	.pricing-clock {
		display: flex;
		align-items: center;
		justify-content: center;

		.clock-service {
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;

			.value {
				background: $color1;;
				color: #fff;
				min-width: 35px;
				max-width: 35px;
				height: 35px;
				font-size: 20px;
				font-weight: 500;
				border-radius: 6px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.name {
				margin-top: 3px;
				font-size: 8px;
				text-transform: uppercase;
				color: #fff;
				font-weight: 500;
			}

			& + .clock-service {
				margin-left: 15px;
			}
		}
	}
}

.section-viewport-point {
	position: absolute;
	top: -25px;
	left: 0;
	opacity: 0;
	visibility: hidden;
}

.checkbox-wrapper {
	margin-top: 15px;
	margin-bottom: 0;
	cursor: pointer;
	width: 100%;
	display: block;
	position: relative;

	.radio-box {
		display: flex;

		.box-square {
			content: '';
			min-width: 12px;
			max-width: 12px;
			height: 12px;
			display: block;
			border-radius: 2px;
			border: 1.5px dotted #999;
			margin-top: 0px;
			margin-right: 10px;
			transition: all 0.3s;
		}

		.box-text {
			font-size: 11px;
			letter-spacing: -0.5px;
			line-height: 10px;
			text-align: left;
		}
	}

	input {
		opacity: 0;
		left: 0;
		position: absolute;
	}
}

.checkbox-wrapper > input:checked + .radio-box {
	.box-square {
		background: $color1;
		border-color: $color1;
	}
}

nav.section-header {
	z-index: 999;
	position: fixed;
	top: 0;
	width: 100%;
	transition: all 0.4s;

	.header-flexbox {
		padding: 40px 0 0 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		transition: padding 0.8s;

		.flexbox-logo {
			transition: all 0.5s;

			img {
				height: 25px;
			}

			.logo-text {
				font-weight: 500;
				font-size: 25px;
				font-weight: 600;
				color: #000;
				transition: all 0.4s;

				span {
					color: #c31432;
					font-size: 40px;
					line-height: 10px;
				}
			}
		}

		.flexbox-cta {
			ul {
				display: flex;
				align-items: center;
				
				li {
					.cta-contact {
						margin-top: 3px;
						display: flex;
						align-items: center;
						transition: all 0.5s;

						span {
							font-size: 14px;
							color: #000;
							white-space: nowrap;
							transition: all 0.4s;

							b {
								font-weight: 700;
							}
						}

						svg {
							margin: 0 8px;
							min-width: 20px;
							max-width: 20px;
							height: 20px;
							fill: $color1;
							transform: translateY(-2px);
						}

						&:hover {
							span {
								b {
									color: $color1;
								}
							}
						}
					}

					& + li {
						margin-left: 30px;
					}
				}
			}
		}

		.flexbox-nav-wrapper {
			transition: all 0.5s;

			.nav-button-close {
				position: absolute;
				display: none;
				width: 100%;
				top: 20px;
				left: 0;

				.container {
					display: flex;
					justify-content: flex-end;

					&:before, &:after {
						content: none;
					}
				}

				button {
					outline: none;
					border: none;
					background: none;
					padding: 15px;
					display: flex;
					justify-content: center;
					align-items: center;

					.button-bar {
						display: block;
						position: absolute;
						width: 30px;
						height: 2px;
						background: #222;

						&:nth-child(1) {
							transform: rotate(45deg);
						}

						&:nth-child(2) {
							transform: rotate(-45deg);
						}
					}
				}
			}

			.flexbox-nav-inner {
				.flexbox-nav {
					display: flex;
					align-items: center;

					li {
						a {
							&:not(.btn) {
								font-weight: 700;
								font-size: 14px;
								color: #000;
								transition: color 0.4s;

								img {
									height: 20px;
									transform: translateY(-3px);
								}

								&:hover {
									color: $color1;
								}
							}

						}

						& + li {
							margin-left: 35px;
						}

						&.btn-cta {
							display: none;
						}

						&.social-media {
							margin-left: 20px;
						}
					}
				}
			}
		}

		.nav-button {
			padding: 10px;
			border: none;
			background: none;
			flex-direction: column;
			align-items: flex-end;
			justify-content: center;
			outline: none;
			display: none;
			
			.button-bar {
				height: 2px;
				width: 28px;
				background: #000;
				border-radius: 10px;
				transition: all 0.4s;

				&:nth-child(2) {
					margin-top: 6px;
					width: 32px;
				}

				&:nth-child(3) {
					margin-top: 6px;
					width: 36px;
				}
			}

			&:hover, &:focus {
				.button-bar {
					width: 36px !important;
				}
			}
		}
	}

	&.scrolling-down {
		transform: translateY(-10px);
		background: none !important;

		.header-flexbox {
			.flexbox-logo, .flexbox-nav-wrapper, .cta-contact {
				opacity: 0;
			}
		}
	}

	&.scrolled {
		background: rgba(255, 255, 255, 0.75);

		.header-flexbox {
			padding: 25px 0;
		}

		&:hover {
			background: rgba(255, 255, 255, 0.95);
		}
	}

	&.white {
		.header-flexbox {
			.flexbox-logo {
				.logo-text {
					color: #fff;
				}
			}

			.flexbox-cta {
				ul {
					li {
						.cta-contact {
							span {
								color: #fff;
							}
						}
					}
				}
			}

			.flexbox-nav-wrapper {
				.flexbox-nav-inner {
					.flexbox-nav {
						li {
							a {
								color: #fff;

								&:hover {
									color: $color1;
								}
							}
						}
					}
				}
			}

			.nav-button {
				.button-bar {
					background: #fff;
				}
			}
		}
	}
}

section.section-welcome {
	position: relative;

	.welcome-background {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		border-radius: 30px;
		overflow: hidden;
		transition: all 1s;

		.background-item {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			opacity: 0;
			transition: opacity 1.5s;

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: top center;
			}

			&.active {
				opacity: 0.85;
			}
		}

		.background-cover {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 35%;
			background: rgb(255,255,255);
			background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
			opacity: 0.9;
			transition: height 0.8s;

			&.scrolled {
				height: 100%;
				opacity: 1;
			}
		}
	}

	.welcome-inner {
		padding-top: 150px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.inner-description {
			width: 43%;

			h1 {
				font-size: 55px;
				color: #000;
				font-weight: 600;

				span {
					color: $color1;
				}
			}

			.inner-claim {
				margin-top: 25px;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				> * {
					& + * {
						margin-left: 10px;
					}
				}

				.claim-slider {
					position: relative;
					transition: all 0.8s;

					p {
						position: absolute;
						bottom: 0;
						transform: translateY(50%);
						left: 0;
						white-space: nowrap;
						opacity: 0;

						&.active {
							opacity: 1;
						}
					}
				}

				p {
					color: #555;
					font-weight: 700;
					transition: all 0.8s;

					b {
						font-weight: 700;
						color: $color1;
					}
				}
			}

			.inner-text {
				margin-top: 70px;

				p {
					margin-top: 35px;
					letter-spacing: 0.2px;
					color: #555;

					b {
						font-weight: 700;
						color: $color1;
					}
				}
			}

			.inner-form {
				margin-top: 30px;
				position: relative;
				background: #f6f8f8;
				border-radius: 50px;
				padding: 15px;
				display: flex;
				align-items: flex-end;

				.input-service {
					width: 100%;
					display: flex;
					align-items: center;
					overflow: hidden;
					position: relative;

					label {
						font-size: 12px;
						color: #000;
					}

					input {
						border: 1px dotted #777;
						border-radius: 50px;
						display: block;
						width: 100%;
						height: 55px;
						outline: none;
						padding: 10px 230px 10px 20px;
						transition: all 0.4s;

						&:focus {
							border: 1px dotted $color1;
						}
					}
				}

				.btn {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 8px;
					text-wrap: nowrap;
				}
			}
		}

		.inner-screen {
			width: 50%;
			padding-top: 39%;
			position: relative;

			.screen-image {
				position: absolute;
				width: 100%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			.screen-products {
				width: 45%;
				padding-top: 45%;
				position: absolute;
				top: 25%;
				left: 8.2%;

				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					opacity: 0;
					transition: all 0.8s;

					&.active {
						opacity: 1;

						&.out {
							transform: translateX(-30px);
							opacity: 0;
						}
					}

					&.out {
						transform: translateX(30px);
					}
				}
			}
		}
	}
}

section.section-text-image {
	&.pt {
		padding-top: 140px;
	}

	&.pb {
		padding-bottom: 140px;
	}

	.text-image-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.inner-image {
			width: 40%;

			.image-wrapper {
				border-radius: 30px;
				overflow: hidden;

				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
		}

		.inner-text {
			width: 50%;

			h2 {
				font-size: 40px;
				color: #000;
				font-weight: 600;

				span {
					color: $color1;
				}
			}

			p {
				margin-top: 35px;
				letter-spacing: 0.2px;
				color: #555;
				font-weight: 400;
			}

			.text-image {
				display: none;
				width: 100%;
				border-radius: 30px;
				overflow: hidden;

				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					object-position: center;
				}
			}

			.accordion {
				width: 100%;
				margin-top: 60px;

				.accordion-item {
					.accordion-header {
						.accordion-button {
							font-size: 20px;
							color: $color1;
							font-weight: 700;
							box-shadow: none;
							text-align: left;
							border: none;
							border-bottom: 0.5px solid #ccc;
							padding: 0 0 10px 0;
							background: none;

							span {
								padding-right: 50px;
							}

							&.collapsed {
								color: #000;
							}
						}
					}

					.accordion-collapse {
						border: none;
					}

					.accordion-body {
						padding: 20px 0 0 0;

						p {
							margin-top: 0;
						}
					}

					& + .accordion-item {
						margin-top: 20px;
					}
				}
			}
		}
	}
}

section.section-about {
	padding: 140px 0;

	&.subpage {
		padding-top: 220px;
	}

	.about-inner {
		position: relative;
		z-index: 555;
		max-width: 800px;
		margin: 0 auto;
		text-align: center;

		.about-shape-1 {
			top: 0;
			right: 0;
			transform: rotate(-45deg) translate(85%, 85%);
			position: absolute;
			height: 150px;
		}

		.about-text {
			position: relative;

			h2 {
				font-size: 45px;
				color: #000;
				font-weight: 500;
				margin-bottom: 50px;

				span {
					color: $color1;
				}
			}

			p {
				max-width: 600px;
				margin: 0 auto;
				font-size: 16px;
				color: #555;
				letter-spacing: 0.2px;
				line-height: 23px;
			}
		}

		.inner-cta {
			margin-top: 70px;
			display: flex;
			align-items: center;

			p {
				font-weight: 700;
				color: #000;
				letter-spacing: 1px;
				font-size: 17px;
			}

			.cta-flexbox {
				margin-left: 50px;
				display: flex;
				align-items: center;

				.btn {
					& + .btn {
						margin-left: 20px;
					}
				}
			}
		}
	}
}

section.section-advantages {
	padding: 100px 0 140px 0;
	background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(246,248,248,1) 100%);

	&.subpage {
		padding: 0;
		background: none;
	}

	&.background {
		padding: 100px 0;
		background: $color1;
	}

	.section-heading {
		padding-left: 50%;

		h2 {
			font-size: 40px;
			color: #000;
			font-weight: 600;

			span {
				color: $color1;
			}
		}
	}

	.advantages-inner-wrapper {
		position: relative;

		.inner-shape {
			position: absolute;
			top: 0;
			left: 55px;
			width: 300px;
			opacity: 0.2;
			transform: translateY(-100%);
		}

		.advantages-inner {
			margin-top: 90px;
			padding: 40px;
			border-radius: 13px;
			background: #fff;
			box-shadow: 10px 20px 40px 0px rgba(0, 0, 0, 0.08);
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			position: relative;
			gap: 30px 0;

			.inner-service {
				width: 32%;
				display: flex;

				.service-number {
					height: 70px;
					min-width: 70px;
					max-width: 70px;
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					background: #f6f8f8;
					color: $color1;
					font-weight: 900;
					font-size: 30px;
				}

				.service-text {
					padding-left: 20px;

					h3 {
						font-size: 20px;
						font-weight: 700;
					}

					p {
						margin-top: 20px;
						letter-spacing: 0.2px;
						color: #555;
						font-weight: 400;
					}
				}
			}

			.inner-collapse {
				.collapse-flexbox {
					width: 100%;
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;

					.inner-service {
						margin-top: 10px;
					}
				}
			}
		}
	}

	.collapse-button {
		margin-top: -18px;
		z-index: 111;
		position: relative;

		.btn {
			box-shadow: none;

			span {
				&:before {
					content: 'Pokaż więcej';
					font-weight: 600;
					font-size: 14px;
					letter-spacing: 0.5px;
				}
			}

			&.active {
				span {
					&:before {
						content: 'Zwiń';
					}
				}
			}
		}
	}

	.advantages-cta {
		position: relative;
		max-width: 800px;
		margin: 0 auto;
		text-align: center;

		.cta-shape-1 {
			bottom: 0;
			left: 50%;
			position: absolute;
			height: 170px;
			transition: all 0.4s;
			animation: mover 1s infinite alternate;

			@keyframes mover {
				0% { transform: translateX(-50%) translateY(35%) scale(1); opacity: 0.3; }
				100% { transform: translateX(-50%) translateY(35%) scale(1.13); opacity: 1; }
			}

			&.active {
				opacity: 0;
				visibility: hidden;
			}
		}

		.cta-text {
			margin-top: 100px;
			position: relative;

			h2 {
				font-size: 45px;
				color: #000;
				font-weight: 500;
				margin-bottom: 20px;

				span {
					color: $color1;
				}
			}

			p {
				max-width: 600px;
				margin: 0 auto;
				font-size: 16px;
				color: #555;
				letter-spacing: 0.2px;
				line-height: 23px;
			}

			.btn {
				margin-top: 70px;

				&.active {
					opacity: 0;
					visibility: hidden;
					transform: translateY(-70px);
				}
			}
		}

		.cta-form {
			position: relative;
			background: #f6f8f8;
			border-radius: 30px;
			padding: 30px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			z-index: 555;
			margin: 0 auto;
			max-width: 500px;
			transition: all 0.8s;

			.heading {
				width: 100%;
				font-size: 25px;
				color: #000;
				font-weight: 600;
			}

			.input-service {
				width: 48%;
				margin-top: 20px;

				label {
					font-size: 12px;
					color: #000;
				}

				input {
					display: block;
					width: 100%;
					height: 45px;
					outline: none;
					border: 1px dotted #000;
					border-radius: 10px;
					padding: 10px;
					text-align: center;
					transition: all 0.4s;

					&:focus {
						border: 1px solid $color1;
					}
				}
			}

			.btn {
				margin-top: 30px;
				width: 100%;
			}

			&.active {
				margin-top: -45px;
			}
		}

		&.wide {
			max-width: 600px;

			.cta-form {
				max-width: 100%;
			}
		}

		&.wide-xl {
			max-width: 1000px;

			.cta-form {
				max-width: 100%;
			}
		}
	}
}

section.section-design {
	.design-inner {
		padding-left: 100px;
		display: flex;

		.inner-description {
			padding: 140px 0;
			max-width: 600px;
			position: relative;
			z-index: 1;

			.description-shape {
				position: absolute;
				bottom: 0;
				transform: translateY(20%) translateX(-100%);
				left: 50px;
				width: 400px;
			}

			h2 {
				font-size: 40px;
				color: #000;
				font-weight: 600;
				margin-bottom: 50px;
				position: relative;

				span {
					color: $color1;
				}
			}

			p {
				letter-spacing: 0.2px;
				color: #555;
				font-weight: 400;
				position: relative;
			}

			.btn {
				position: relative;
				margin-left: 100px;
				margin-top: 50px;
			}

			.description-text {
				padding-left: 100px;
			}
		}

		.inner-image {
			position: relative;

			img {
				position: absolute;
				left: 0;
				top: -50px;
				height: calc(100% + 120px);
			}
		}
	}
}

section.section-skins {
	.skins-inner {
		border-radius: 30px;		
		padding: 100px 0 70px 0;
		background: #f6f8f8;
	}

	.section-heading {
		text-align: center;

		h2 {
			font-size: 40px;
			color: #000;
			font-weight: 600;
			margin-bottom: 20px;

			span {
				color: $color1;
			}
		}

		p {
			max-width: 500px;
			width: 95%;
			margin: 0 auto;
			letter-spacing: 0.2px;
			color: #555;
			font-weight: 400;
		}
	}

	.swiper-outer {
		margin-top: 30px;
		position: relative;

		.outer-cover {
			z-index: 111;
			pointer-events: none;
			position: absolute;
			top: 0;
			height: 100%;

			&.left {
				left: 0;
				width: 400px;
				background: linear-gradient(90deg, rgba(246,248,248,1) 0%, rgba(246,248,248,0) 100%);
			}

			&.right {
				right: 0;
				width: 400px;
				background: linear-gradient(-90deg, rgba(246,248,248,1) 0%, rgba(246,248,248,0) 100%);
			}
		}
	}

	.swiper-skins {
		.swiper-slide {
			width: auto;
		}

		.skin-item {
			outline: none;
			display: block;
			width: 350px;
			padding: 40px 40px 0 40px;

			&.premium {
				&:after {
					content: 'Premium';
					position: absolute;
					top: 45px;
					left: 50%;
					padding: 5px 12px;
					color: #333;
					font-weight: 700;
					border-radius: 5px;;
					font-size: 12px;
					background: gold;
					transform: translate(-50%, -50%);
				}
			}

			.service-image {
				width: 100%;
				padding-top: 100%;
				border-radius: 20px;
				box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.2);
				position: relative;
				transition: all 0.5s;
				overflow: hidden;

				img {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					object-fit: cover;
					object-position: center;
				}
			}

			.service-number {
				color: #000;
				opacity: 0.15;
				font-weight: 900;
				font-size: 70px;
				line-height: 67px;
				text-align: center;
			}

			.service-heading {
				margin-top: 35px;
				text-align: center;
				color: #000;
				font-size: 20px;
				font-weight: 700;
			}

			.service-description {
				margin-top: 15px;
				text-align: center;
				font-size: 16px;
				color: #555;
				letter-spacing: 0.2px;
				line-height: 23px;
				position: relative;
			}
		}
	}
}

section.section-cooperate {
	padding: 100px 0;

	.section-heading {
		text-align: center;

		h2 {
			font-size: 40px;
			color: #000;
			font-weight: 600;
			margin-bottom: 20px;

			span {
				color: $color1;
			}
		}

		p {
			max-width: 500px;
			margin: 0 auto;
			letter-spacing: 0.2px;
			color: #555;
			font-weight: 400;
		}
	}

	.swiper-outer {
		margin-top: 40px;
		position: relative;

		.outer-cover {
			z-index: 111;
			pointer-events: none;
			position: absolute;
			top: 0;
			height: 100%;

			&.left {
				left: 0;
				width: 300px;
				background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
			}

			&.right {
				right: 0;
				width: 300px;
				background: linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
			}
		}
	}

	.swiper-cooperate {
		.swiper-wrapper {
			align-items: center;
		}

		.swiper-slide {
			width: auto;
		}

		.cooperate-item {
			outline: none;
			display: block;
			width: 150px;

			img {
				width: 100%;
			}

			&.w-1 {
				width: 140px;
			}

			&.w-2 {
				width: 130px;
			}

			&.w-3 {
				width: 120px;
			}

			&.w-4 {
				width: 110px;
			}
		}
	}
}

section.section-form {
	&.pt {
		padding-top: 100px;
	}

	&.pb {
		padding-bottom: 100px;
	}

	.form-inner {
		padding: 70px;
		border-radius: 30px;
		background: rgb(195,20,50);
		background: linear-gradient(90deg, rgba(195,20,50,1) 0%, rgba(132,10,31,1) 100%);
		display: flex;
		align-items: center;
		justify-content: space-between;

		.inner-text {
			width: 47%;

			h2 {
				font-size: 40px;
				color: #fff;
				font-weight: 600;
				margin-bottom: 20px;
			}

			p {
				max-width: 500px;
				letter-spacing: 0.2px;
				color: #eee;
				font-weight: 400;
			}

			.text-contact {
				margin-top: 40px;
				display: flex;
				align-items: center;

				> * {
					& + * {
						margin-left: 30px;
					}
				}	

				.contact-service {
					display: flex;
					align-items: center;

					img {
						margin-right: 8px;
					}

					a {
						font-size: 26px;
						color: #fff;
						font-weight: 600;
					}

					&:nth-child(2) {
						a {
							transform: translateY(-3px);
						}
					}
				}
			}
		}

		.inner-form {
			width: 50%;
			position: relative;
			background: rgba(255, 255, 255, 0.1);
			border-radius: 50px;
			padding: 15px;
			display: flex;
			align-items: flex-end;

			.input-service {
				width: 100%;
				display: flex;
				align-items: center;
				overflow: hidden;
				position: relative;

				label {
					font-size: 12px;
					color: #000;
				}

				input {
					border: 1px dotted rgba(255, 255, 255, 0.4);
					border-radius: 50px;
					display: block;
					width: 100%;
					height: 55px;
					outline: none;
					background: rgba(255, 255, 255, 0.1);
					padding: 10px 230px 10px 20px;
					transition: all 0.4s;
					color: #fff;

					&::placeholder {
						color: #ddd;
					}

					&:focus {
						border: 1px dotted rgba(255, 255, 255, 1);
					}
				}
			}

			.btn {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 8px;
				text-wrap: nowrap;
			}
		}
	}
}

section.section-pricing {
	padding: 100px 0;

	.section-heading-wrapper {
		display: flex;
		justify-content: space-between;

		.section-heading {
			text-align: left;
		}

		.pricing-clock-wrapper {
			margin-top: 0;

			.pricing-clock-heading {
				text-align: right;
			}

			.pricing-clock {
				justify-content: flex-end;
				margin-top: 15px;
			}
		}
	}

	.section-heading {
		text-align: center;

		h2 {
			font-size: 40px;
			color: #000;
			font-weight: 600;
			margin-bottom: 20px;

			span {
				color: $color1;
			}
		}

		p {
			max-width: 500px;
			margin: 0 auto;
			letter-spacing: 0.2px;
			color: #555;
			font-weight: 400;
		}

	}

	.pricing-clock-wrapper {
		margin-top: 50px;

		.pricing-clock-heading {
			text-align: center;

			h3 {
				font-size: 20px;
				color: #000;
				font-weight: 700;

				span {
					color: $color1;
				}
			}

			p {
				max-width: 600px;
				margin: 0 auto;
				font-size: 16px;
				color: #555;
				letter-spacing: 0.2px;
				line-height: 23px;
			}
		}

		.pricing-clock {
			margin-top: 25px;
			display: flex;
			align-items: center;
			justify-content: center;

			.clock-service {
				text-align: center;

				.value {
					background: $color1;;
					color: #fff;
					min-width: 50px;
					max-width: 50px;
					height: 50px;
					font-size: 28px;
					font-weight: 500;
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.name {
					margin-top: 3px;
					font-size: 12px;
					text-transform: uppercase;
					color: #000;
					font-weight: 500;
				}

				& + .clock-service {
					margin-left: 15px;
				}
			}
		}
	}

	.table-wrapper {
		margin-top: 40px;
		max-height: 650px;
		border-radius: 15px;
		overflow: hidden;
		box-shadow: 0px 110px 50px 0px rgba(0, 0, 0, 0.08);
		position: relative;
		transition: all 0.8s;

		.wrapper-cover {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 280px;
			background: rgb(211,211,209);
			background: linear-gradient(180deg, rgba(230,230,228,0) 0%, rgba(211,211,209,1) 100%);
			transition: all 0.8s;
		}

		&.active {
			max-height: 4500px;

			.wrapper-cover {
				opacity: 0;
				visibility: hidden;
				box-shadow: 0px 90px 30px 0px rgba(0, 0, 0, 0);
			}
		}
	}

	.table-responsive {
		overflow-x: hidden;
	}

	table {
		margin-top: 90px;
		margin-bottom: 0;
		border-radius: 15px;

		tbody {
			tr {
				td {
					vertical-align: middle;
					border: none;
					text-align: center;

					&.bestseller {
						position: relative;

						&:before {
							content: 'Najpopularniejszy';
							text-wrap: nowrap;
							font-weight: 500;
							padding: 3px 10px;
							border-radius: 20px;
							background: $color1;
							color: #fff;
							font-size: 12px;
							letter-spacing: 1px;
							position: absolute;
							top: -10px;
							left: 50%;
							transform: translate(-50%, -100%);
						}
					}

					&.later {
						position: relative;

						&:before {
							content: 'Już wkrótce';
							text-wrap: nowrap;
							font-weight: 500;
							padding: 3px 10px;
							border-radius: 20px;
							background: #000;
							color: #fff;
							font-size: 12px;
							letter-spacing: 1px;
							position: absolute;
							top: -10px;
							left: 50%;
							transform: translate(-50%, -100%);
						}
					}

					.heading {
						padding-top: 3px;
						color: #000;
						font-weight: 700;
						font-size: 19px;

						strong {
							color: $color1;
						}
					}

					.price-heading {
						margin-top: 10px;
						color: #000;
						font-size: 13.5px;
					}

					.price {
						font-weight: 700;
						font-size: 35px;
						color: #000;
						margin-top: 5px;
						line-height: 31px;
						transform: translateX(19px);

						&.red {
							color: $color1;
							margin-top: 10px;
							font-size: 35px * 1.3;
							line-height: 31px * 1.3;
						}

						span {
							color: inherit;
						}

						small {
							font-size: 14px;
							font-weight: 300;
							color: #000;
						}

						&.promotion-active {
							position: relative;
							color: #888;
							transform: scale(0.7) translateX(19px);

							&:after {
								content: '';
								width: 40%;
								height: 2px;
								background: $color1;
								position: absolute;
								top: 10px;
								left: 23%;
								transform: rotate(-5deg);
							}
						}
					}

					.price-promotion {
						font-weight: 700;
						font-size: 35px;
						color: $color1;
						margin-top: 5px;
						line-height: 31px;
						transform: translateX(19px);

						span {
							color: inherit;
						}

						small {
							font-size: 14px;
							font-weight: 300;
							color: $color1;
						}
					}

					.price-omnibus {
						font-size: 10px;
					}

					img {
						height: 20px;
					}

					span {
						color: olivedrab;
					}

					.value {
						position: relative;

						&.info {
							cursor: pointer;

							&:after {
								content: '';
								height: 10px;
								width: 10px;
								background: url('../img/icons/information.svg') center;
								background-size: cover;
								position: absolute;
								right: 0;
								top: 50%;
								transform: translate(-100%, -50%);
							}
						}

						&.alert-dot {
							&:before {
								content: 'Już wkrótce';
								font-size: 10px;
								background: #000;
								color: #fff;
								font-weight: 500;
								line-height: 10px;
								display: block;
								width: 70px;
								margin: 0 auto;
								padding: 3px 0 2px 0;
								border-radius: 20px;
							}
						}
					}

					.table-form {
						.input-service {
							label {
								font-size: 12px;
								color: #000;
							}

							.service-input-wrapper {
								position: relative;

								svg {
									position: absolute;
									left: 15px;
									top: 50%;
									height: 16px;
									width: 16px;
									fill: #000;
									transform: translateY(-50%);
								}

								input {
									display: block;
									width: 100%;
									height: 38px;
									outline: none;
									border: 1px dotted #000;
									border-radius: 10px;
									padding: 10px;
									text-align: center;
									transition: all 0.4s;

									&:focus {
										border: 1px solid $color1;
									}
								}
							}

							& + .input-service {
								margin-top: 15px;
							}
						}

						.checkbox-wrapper {
							span {
								color: #000;
							}
						}

						.btn {
							width: 100%;
							margin-top: 20px;
						}
					}

					&:nth-child(1) {
						width: 35%;
					}

					&:nth-child(3) {
						background: #F9F1F1;;
					}

					&:nth-child(2), &:nth-child(3), &:nth-child(4) {
						width: 65% / 3;
					}

					&:last-child {
						// opacity: 0.15;
					}
				}

				&:not(:first-child) {
					td {
						border-top: 0.7px solid rgba(0, 0, 0, 0.05);
					}
				}

				&:first-child {
					td {
						&:first-child {
							border-radius: 15px 0 0 0;
						}

						&:nth-child(3) {
							border-radius: 15px 15px 0 0;
						}
					}
				}

				&:last-child {
					td {
						padding: 20px;

						&:first-child {
							border-radius: 0 0 0 15px;
						}

						&:nth-child(3) {
							border-radius: 0 0 15px 15px;
						}
					}
				}
			}
		}
	}

	.btn-pricing-more {
		text-align: center;
		margin-top: -20px;
		position: relative;

		.btn {
			box-shadow: none;
			transition: all 0.8s;

			span {
				&:before {
					content: 'Pokaż wszystkie funkcje';
					font-weight: 600;
					font-size: 14px;
					letter-spacing: 0.5px;
				}
			}

			&.active {
				transform: translateY(35px);

				span {
					&:before {
						content: 'Zwiń';
					}
				}
			}
		}
	}

	.pricing-cta {
		position: relative;
		text-align: center;

		.cta-shape-1 {
			bottom: 0;
			left: 50%;
			position: absolute;
			height: 170px;
			transition: all 0.4s;
			animation: mover 1s infinite alternate;
		}

		.cta-text {
			margin-top: 130px;
			position: relative;

			h2 {
				font-size: 40px;
				color: #000;
				font-weight: 600;
				margin-bottom: 20px;

				span {
					color: $color1;
				}
			}

			p {
				max-width: 600px;
				margin: 0 auto;
				letter-spacing: 0.2px;
				color: #555;
				font-weight: 400;
			}

			.btn {
				margin-top: 70px;
			}
		}
	}
}

section.section-shop-test {
	padding: 140px 0;
	background: $color1;
	position: relative;

	.shop-test-image {
		position: absolute;
		top: 0;
		right: 0;
		width: 50%;
		height: 100%;

		.image-cover {
			background: linear-gradient(-90deg, rgba(195,20,50,0) 0%, rgba(195,20,50,1) 100%);
			position: absolute;
			top: 0;
			left: 0;
			width: 65%;
			height: 100%;
		}

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: left center;
		}
	}

	.shop-test-inner {
		width: 50%;
		position: relative;

		.inner-text {
			h2 {
				max-width: 600px;
				font-size: 45px;
				color: #fff;
				font-weight: 500;
				margin-bottom: 20px;
			}

			p {
				max-width: 600px;
				font-size: 16px;
				color: #fff;
				letter-spacing: 0.2px;
				line-height: 23px;
			}

			.btn {
				margin-top: 50px;
			}
		}
	}
}

section.section-marketing {
	padding: 140px 0;
	

	.marketing-inner {
		position: relative;
		display: flex;
		justify-content: center;

		.inner-description {
			position: relative;

			.description-shape {
				position: absolute;
				bottom: -50px;
				transform: translateX(-100%);
				left: 100px;
				width: 400px;
			}

			h2 {
				font-size: 45px;
				color: #000;
				font-weight: 500;
				margin-bottom: 50px;
				position: relative;

				span {
					color: $color1;
				}
			}

			p {
				font-size: 16px;
				color: #555;
				letter-spacing: 0.2px;
				line-height: 23px;
				position: relative;
			}

			.btn {
				margin-top: 50px;
			}

			.description-text {
				max-width: 1000px;
				padding: 50px;
				border-radius: 15px;
				box-shadow: 10px 20px 50px 0px rgba(0, 0, 0, 0.15);
				display: flex;
				justify-content: space-between;
				position: relative;

				.text-shape {
					position: absolute;
					top: 0;
					right: 55px;
					width: 300px;
					opacity: 0.2;
					transform: translateY(-100%);
				}

				.text-service {
					width: 45%;
				}

				h3 {
					font-size: 20px;
					color: #000;
					font-weight: 500;
					margin-bottom: 10px;

					span {
						color: $color1;
					}
				}
			}
		}
	}
}

section.section-faq {
	padding: 100px 0 0 0;
	position: relative;
	background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(246,248,248,1) 100%);

	.faq-inner {
		display: flex;

		.inner-image {
			margin-right: 150px;
			min-width: 300px;
			max-width: 300px;
			position: relative;

			img {
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				height: 70%;
				max-height: 420px;
			}
		}

		.inner-accordion {
			position: relative;
			width: 100%;

			.accordion-shape {
				position: absolute;
				bottom: 0;
				transform: translateY(20%) translateX(-100%);
				left: 50px;
				width: 400px;
			}

			h2 {
				font-size: 40px;
				color: #000;
				font-weight: 600;
				margin-bottom: 50px;
				position: relative;

				span {
					color: $color1;
				}
			}

			.accordion {
				width: 100%;

				.accordion-item {
					.accordion-header {
						.accordion-button {
							font-size: 20px;
							color: $color1;
							font-weight: 700;
							box-shadow: none;
							text-align: left;
							border: none;
							border-bottom: 0.5px solid #ccc;
							padding: 0 0 10px 0;
							background: none;

							span {
								padding-right: 50px;
							}

							&.collapsed {
								color: #000;
							}
						}
					}

					.accordion-collapse {
						border: none;
					}

					.accordion-body {
						padding: 20px 0 0 0;

						p {
							letter-spacing: 0.2px;
							color: #555;
							font-weight: 400;

							a {
								color: $color1;
								text-decoration: underline;
							}
						}
					}

					& + .accordion-item {
						margin-top: 20px;
					}
				}
			}

			.btn {
				margin-top: 50px;
			}
		}
	}
}

section.section-payment-partner {
	background: #e2e2e2;
	background: #072653;
	padding: 140px 0;
	position: relative;

	.payment-partner-background {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		opacity: 0.3;
	}

	.payment-partner-inner {
		position: relative;
		display: flex;
		align-items: center;

		.inner-description {
			h2 {
				font-size: 45px;
				color: #fff;
				font-weight: 500;
				margin-bottom: 20px;

				span {
					color: $color1;
				}
			}

			p {
				max-width: 600px;
				font-size: 16px;
				color: #fff;
				letter-spacing: 0.2px;
				line-height: 23px;
				font-weight: 300;

				strong {
					font-weight: 800;
				}
			}

			.btn {
				margin-top: 50px;
			}
		}

		.inner-image {
			padding-left: 70px;

			.image-text {
				padding-left: 195px;
				margin-bottom: 20px;
				font-size: 20px;
				color: #fff;
				font-weight: 500;

				span {
					font-size: 13px;
					text-transform: uppercase;
					font-weight: 300;
				}
			}

			img {
				height: 100px;
			}
		}
	}
}

section.section-thank-you {
	background: #f8f8f8;
	overflow: hidden;
	position: relative;

	.inner-shape-3 {
		position: absolute;
		bottom: 20px;
		right: 20px;
		width: 400px;
	}

	.thank-you-inner {
		padding: 80px 0;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;

		.inner-shape-1 {
			position: absolute;
			top: 0;
			left: 50%;
			width: 250px;
			transform: translate(-50%, -50%);
			animation: thank-mover 1s infinite alternate;

			@keyframes thank-mover {
				0% { transform: translateX(-50%) translateY(-50%) scale(1); }
				100% { transform: translateX(-50%) translateY(-50%) scale(1.13); }
			}
		}

		.inner-shape-2 {
			position: absolute;
			top: 10%;
			left: -300px;
			width: 500px;
		}

		.inner-logo {
			text-align: center;
			font-weight: 500;
			font-size: 50px;
			font-weight: 600;
			color: #000;
			transition: all 0.4s;

			span {
				color: #c31432;
				font-size: 80px;
				line-height: 10px;
			}
		}

		.inner-description {
			margin-top: 30px;
			text-align: center;
			max-width: 500px;

			p {
				font-size: 26px;
				color: #000;
			}
		}

		.inner-cta {
			margin-top: 100px;
			text-align: center;

			p {
				color: #000;
				font-size: 13px;
				text-transform: uppercase;
				letter-spacing: 1px;
				margin-bottom: 10px;
				font-weight: 400;
			}

			img {
				height: 50px;
			}
		}
	}
}

section.static-page-section-content {
	padding: 250px 0 0 0;
	transition: all 0.4s;

	.content-inner {
		max-width: 770px;
		width: 100%;
		margin: 0 auto;

		p, li {
			color: #000;
			font-size: 14px;
			line-height: 25px;
			letter-spacing: 0.3px;
		}

		ol, ul {
			padding-left: 17px;
		}

		h1 {
			font-size: 45px;
			color: #000;
			font-weight: 500;
		}

		h2 {
			font-size: 28px;
			color: #000;
			font-weight: 500;
		}

		h3 {
			font-size: 24px;
			color: #000;
			font-weight: 500;
		}

		h4 {
			font-size: 21px;
			color: #000;
			font-weight: 500;
		}

		h5 {
			font-size: 18px;
			color: #000;
			font-weight: 500;
		}

		h6 {
			font-size: 16px;
			color: #000;
			font-weight: 500;
		}
	}
}

section.formularz-section-content {
	position: relative;

	.content-close {
		position: absolute;
		top: 30px;
		right: 30px;
		border: 1px solid #ddd;
		border-radius: 8px;
		width: 45px;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			height: 15px;
			width: 15px;
		}
	}

	.content-inner {
		padding: 30px 0;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.section-heading {
			text-align: center;
			margin-bottom: 30px;

			h2 {
				font-size: 40px;
				color: #000;
				font-weight: 600;

				span {
					color: $color1;
				}
			}
		}
	}
}

section.centrum-pomocy-section-content {
	padding-top: 200px;

	.section-heading {
		text-align: center;
		margin-bottom: 50px;

		h1 {
			font-size: 55px;
			color: #000;
			font-weight: 600;

			span {
				color: $color1;
			}
		}
	}

	.content-flexbox {
		display: flex;
		flex-wrap: wrap;

		.flexbox-service {
			width: calc(100% / 3);
			padding: 40px;
			position: relative;

			.service-heading {
				text-align: center;
				font-size: 25px;
				color: #000;
				font-weight: 500;
			}

			ul {
				margin-top: 25px;

				li {
					button, a {
						border: none;
						font-size: 17px;
						font-weight: 500;
						color: $color1;
						background: none;
						letter-spacing: 1px;
						display: block;
						width: 100%;
						text-align: center;
						position: relative;
						outline: none;
						transition: all 0.3s;

						&:hover {
							color: $color1;
						}
					}

					& + li {
						margin-top: 12px;
						padding-top: 12px;
						border-top: 0.5px dotted #ccc;
					}
				}
			}

			&:after {
				content: '';
				height: 100%;
				width: 1px;
				border-right: 1px dotted $color1;
				position: absolute;
				top: 0;
				right: 0;
			}

			&:before {
				content: '';
				height: 1px;
				width: 100%;
				border-bottom: 1px dotted $color1;
				position: absolute;
				top: 0;
				left: 0;
			}

			&:nth-child(3n) {
				&:after {
					content: none;
				}
			}

			&:nth-child(1), &:nth-child(2), &:nth-child(3) {
				&:before {
					content: none;
				}
			}
		}
	}
}

section.dodatkowe-skorki-section-content {
	padding-top: 200px;

	.section-heading {
		text-align: center;
		margin-bottom: 50px;

		h1 {
			font-size: 45px;
			color: #000;
			font-weight: 500;
			margin-bottom: 20px;

			span {
				color: $color1;
			}
		}

		p {
			max-width: 600px;
			margin: 0 auto;
			font-size: 16px;
			color: #555;
			letter-spacing: 0.2px;
			line-height: 23px;
		}
	}

	.content-flexbox {
		display: flex;
		flex-wrap: wrap;

		.flexbox-service {
			width: 48%;

			.service-image {
				img {
					width: 100%;
				}
			}

			.service-heading {
				text-align: center;
				font-size: 25px;
				color: #000;
				font-weight: 500;
			}
		}
	}
}

section.testuj-sklep-section-content {
	padding-top: 200px;
	padding-bottom: 100px;
	position: relative;

	.content-background {
		position: absolute;
		top: 115px;
		right: 0;
		height: calc(100% - 115px);
		width: 55%;

		.background-cover {
			background: linear-gradient(-90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
			position: absolute;
			top: 0;
			left: 0;
			width: 65%;
			height: 100%;
		}

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center top;
		}
	}

	.section-heading {
		margin-bottom: 50px;
		width: 50%;
		max-width: 600px;
		position: relative;

		h1 {
			font-size: 45px;
			color: #000;
			font-weight: 500;
			margin-bottom: 20px;

			span {
				color: $color1;
			}
		}

		p {
			font-size: 16px;
			color: #555;
			letter-spacing: 0.2px;
			line-height: 23px;
		}
	}

	.content-inner {
		margin-top: 80px;
		width: 60%;
		display: flex;
		justify-content: space-between;
		position: relative;

		.inner-advantages {
			width: 30%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.advantage-service {
				padding: 15px;
				background: $color1;
				border-radius: 10px;
				text-align: center;

				.name {
					font-size: 25px;
					line-height: 25px;
					color: #fff;
					font-weight: 500;
				}

				.text {
					margin-top: 8px;
					font-size: 16px;
					color: #fff;
					letter-spacing: 0.2px;
					line-height: 16px;
				}
			}
		}

		.inner-form {
			width: 65%;
			position: relative;
			background: #fff;
			box-shadow: 10px 20px 50px 0px rgba(0, 0, 0, 0.15);
			border-radius: 13px;
			padding: 30px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			z-index: 555;
			transition: all 0.8s;

			.heading {
				width: 100%;
				font-size: 25px;
				color: #000;
				font-weight: 500;
			}

			.input-service {
				width: 48%;
				margin-top: 20px;

				label {
					font-size: 12px;
					color: #000;
				}

				input {
					display: block;
					width: 100%;
					height: 45px;
					outline: none;
					border: 1px dotted #000;
					border-radius: 10px;
					padding: 10px;
					text-align: center;
					transition: all 0.4s;

					&:focus {
						border: 1px solid $color1;
					}
				}
			}

			.btn {
				margin-top: 30px;
				width: 100%;
			}
		}
	}
}

section.uruchom-sklep-section-content {
	padding-top: 200px;
	padding-bottom: 100px;
	position: relative;

	.content-background {
		position: absolute;
		top: 115px;
		right: 0;
		height: calc(100% - 115px);
		width: 55%;

		.background-cover {
			background: linear-gradient(-90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
			position: absolute;
			top: 0;
			left: 0;
			width: 65%;
			height: 100%;
		}

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center top;
		}
	}

	.section-heading {
		width: 50%;
		max-width: 600px;
		position: relative;

		h1 {
			font-size: 65px;
			color: #000;
			font-weight: 500;

			span {
				color: $color1;
			}
		}
	}

	.content-inner {
		margin-top: 30px;
		width: 60%;
		position: relative;

		.inner-advantages {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			gap: 30px 0;

			.advantage-service {
				width: 30%;
				padding: 15px;
				background: $color1;
				border-radius: 10px;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;

				.name {
					font-size: 20px;
					line-height: 20px;
					color: #fff;
					font-weight: 500;
				}
			}
		}

		.inner-description {
			margin-top: 50px;
			width: 80%;

			p {
				font-size: 20px;
				color: #555;
				letter-spacing: 0.2px;
				line-height: 26px;

				b {
					font-weight: 700;
					color: $color1;
				}
			}
		}

		.inner-form {
			margin-top: 50px;
			position: relative;
			background: #fff;
			box-shadow: 10px 20px 50px 0px rgba(0, 0, 0, 0.15);
			border-radius: 13px;
			padding: 30px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			z-index: 555;
			transition: all 0.8s;

			.heading {
				width: 100%;
				font-size: 25px;
				color: #000;
				font-weight: 500;
			}

			.input-service {
				width: 23%;
				margin-top: 20px;

				label {
					font-size: 12px;
					color: #000;
				}

				input {
					display: block;
					width: 100%;
					height: 45px;
					outline: none;
					border: 1px dotted #000;
					border-radius: 10px;
					padding: 10px;
					text-align: center;
					transition: all 0.4s;

					&:focus {
						border: 1px solid $color1;
					}
				}
			}

			.btn {
				margin-top: 30px;
				width: 100%;
			}
		}
	}
}

section.section-footer {
	padding-top: 100px;

	.footer-inner {
		display: flex;

		.inner-service {
			&.w-100 {
				text-align: right;
			}

			ul {
				.heading {
					white-space: nowrap;
					margin-bottom: 20px;
					font-weight: 600;
				}

				li {
					a {
						font-size: 16px;
						color: #000;
						white-space: nowrap;
						transition: all 0.4s;
					}
				}

				li + li {
					margin-top: 10px;
				}
			}

			.social-media {
				display: flex;
				align-items: center;

				li {
					a {
						svg {
							height: 18px;
							width: 18px;
							fill: #000;
							transition: all 0.4s;
						}
					}

					& + li {
						margin-top: 0;
					}

					&:not(:last-child) {
						margin-right: 10px;
					}
				}
			}

			address {
				margin-bottom: 0;

				a {
					display: block;
					font-size: 16px;
					color: #000;
					transition: all 0.5s;
				}

				p {
					margin-top: 15px;
					font-size: 11px;
					color: #000;
				}
			}

			.service-description {
				margin-bottom: 15px;

				p {
					font-weight: 600;
					color: #000;

				}
			}

			& + .inner-service {
				margin-left: 125px;
			}
		}
	}

	.footer-credits {
		margin-top: 60px;
		padding: 35px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: 1px solid #ddd;

		.credits-copyright {
			display: flex;
			align-items: center;

			.copyright-logo {
				img {
					height: 25px;
				}

				.logo-text {
					font-weight: 500;
					font-size: 25px;
					font-weight: 600;
					color: #000;
					transition: all 0.4s;

					span {
						color: #c31432;
						font-size: 40px;
						line-height: 10px;
					}
				}
			}

			.divider {
				border-right: 1px solid #ddd;
				width: 1px;
				height: 30px;
				margin: 0 20px;
			}

			p {
				font-size: 14px;
				color: #000;

				a {
					color: inherit;
					transition: all 0.5s;
				}
			}
		}

		.credits-payment {
			ul {
				display: flex;
				align-items: center;

				li {
					img {
						height: 15px;
					}
				}

				li + li {
					margin-left: 15px;
				}
			}
		}
	}
}

.cookies-alert {
	position: fixed;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 888;
	width: 330px;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 2px;
	padding: 15px 40px 15px 20px;
	transition: all 0.4s;

	.btn-close {
		position: absolute;
		top: 12px;
		right: 6px;
		outline: none;
		box-shadow: none;
		height: auto;
		width: auto;
		transition: opacity 0.4s;
		border: none;
		background: none;
		position: absolute;
		background: none;
		padding: 10px;
		cursor: pointer;

		span {
			position: absolute;
			display: block;
			left: 0;
			top: 50%;
			width: 12px;
			height: 1px;
			background: #fff;

			&:nth-child(1) {
				transform: rotate(45deg) translateY(-50%);
			}

			&:nth-child(2) {
				transform: rotate(-45deg) translateY(-50%);
			}
		}

		&:after {
			content: none;
		}
	}

	p {
		font-size: 11px;
		font-weight: 300;
		color: #fff;

		a {
			color: inherit;
			text-decoration: underline;
		}
	}

	&.hidden {
		visibility: hidden;
		opacity: 0;
	}
}